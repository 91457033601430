import "./NewClientLeadTable.css";

import Header from "./components/Header";
import Table from "./components/Table";
import { ApiDataProvider } from "../Leads/MasterDataContextProvider";
import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MultiSelectBox from "../../components/TextFields/MultiSelectBox";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";

const initialState = {
  selectedRow: "",
  reloadTable: false,
  deleteList: [],
  saveColumList: "",
  globalSearchedValue: "",
  validitySearch: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "selectedRow": {
      return {
        ...state,
        selectedRow: action.payload,
      };
    }

    case "deleteList": {
      return {
        ...state,
        deleteList: action.payload,
      };
    }

    case "saveColumList": {
      return {
        ...state,
        saveColumList: action.payload,
      };
    }

    case "globalSearchedValue": {
      return {
        ...state,
        globalSearchedValue: action.payload,
      };
    }

    case "reloadTable": {
      return {
        ...state,
        reloadTable: action.payload,
      };
    }

    case "validitySearch": {
      return {
        ...state,
        validitySearch: action.payload,
      };
    }
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const NewClientLeadTable = () => {
  const location = useLocation();
  const pathName = location.pathname === "/Leads/listLeads" ? "Lead" : "Client";

  const [
    {
      selectedRow,
      reloadTable,
      deleteList,
      saveColumList,
      globalSearchedValue,
      validitySearch,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const form = useForm({
    defaultValues: {
      defaultSearchValidity: [
        // "premium",
        // "new allocated",
        // "new later",
        // "tenure ending",
        // "new unallocated",
        // "renewed",
      ],
    },
  });

  const { control, watch } = form;

  const currentValidity = watch("defaultSearchValidity");

  useEffect(() => {
    dispatch({ type: "validitySearch", payload: currentValidity });
  }, [currentValidity]);

  return (
    <div className="new-client">
      <Header
        selectedRow={selectedRow}
        deleteList={deleteList}
        saveColumList={saveColumList}
        onDispatch={dispatch}
      />
      <div className="new-cient-container">
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "20px" }}
        >
          <h4> {pathName} List</h4>
          {pathName !== "Lead" && (
            <Box width={"400px"}>
              <MultiSelectBox
                control={control}
                name={"defaultSearchValidity"}
                isRequired
                disableCloseOnSelect={false}
                selectAllOption={true}
                menuList={[
                  { id: "premium", value: "Premium" },
                  { id: "free", value: "Free" },
                  { id: "new allocated", value: "New Allocated" },
                  { id: "new later", value: "New Later" },
                  { id: "tenure ending", value: "Tenure Ending" },
                  { id: "new unallocated", value: "New Unallocated" },
                  { id: "renewed", value: "Renewed" },
                ]}
                //  inputLabel={"Source"}
                arrangeModal={{
                  marginTop: "-50px",
                  width: "300px",
                  marginLeft: "0px",
                }}
              />
            </Box>
          )}
        </Box>

        <ApiDataProvider>
          <Table
            onDispatch={dispatch}
            reloadTable={reloadTable}
            globalSearchedValue={globalSearchedValue}
            validitySearch={validitySearch}
          />
        </ApiDataProvider>
      </div>
    </div>
  );
};

export default NewClientLeadTable;
