import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import ControlledDatepicker from "../../../components/ControlledDatepicker";
import dayjs from "dayjs";
import { set } from "date-fns";
import moment from "moment/moment";
import { MuiTelInput } from "mui-tel-input";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

const besttimetocallmaster = [
  "6am - 10am IST",
  "10am - 2pm IST",
  "2pm - 6pm IST",
  "6pm - 10pm IST",
  "10pm - 2am IST",
];

const preferredLanguage = [
  "English",
  "Tamil",
  "Telugu",
  "Hindi",
  "Bengali",
  "Kannada",
  "Urdu",
  "Malayalam",
  "Arabic",
];

const BasicInformation = ({ displaydata, formdata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [infoview, setInforview] = useState({});

  const defaultValues = {
    createDate: new Date(),
    dob: null,
  };
  const methods = useForm({
    defaultValues,
    profession_details: "",
  });

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [height, setHeight] = useState([]);
  const [source, setSource] = useState([]);
  const [weight, setWeight] = useState("");
  const [languageOne, setLanguageOne] = useState("");
  const [languageTwo, setLanguageTwo] = useState("");
  const [profession, setProfession] = useState("");

  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedPrefferedTime, setSelectedPrefferedTime] = useState("");

  const [bmi, setBMI] = useState("");
  const [goal, setGoal] = useState([]);
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    register,
    formState: { errors, isValid },
  } = methods;
  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getPersonalinfoMaster`,
    })
      .then((response) => {
        setGoal(response.data.body.goals);
        setMasters({
          ...masters,
          emotional_health: response.data.body.emotional_health,
          profession: [...response.data.body.profession],
        });
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (displaydata) {
      setPhoneNumber(displaydata?.basicInfo?.contact_number);
      setSelectedHeight(displaydata?.basicInfo?.height);
      setSelectedSource(displaydata?.basicInfo?.source);
      setWeight(displaydata?.basicInfo?.current_weight);
      setLanguageOne(displaydata?.basicInfo?.preferred_language); //mother_tongue
      setLanguageTwo(displaydata?.basicInfo?.mother_tongue);
      setProfession(displaydata?.basicInfo?.occupation);
      setSelectedGoal(displaydata?.basicInfo?.goal);
      setSelectedPrefferedTime(displaydata?.basicInfo?.prefer_contact_times);

      let basicinfo = displaydata?.basicInfo;
      let dateval = dayjs(basicinfo?.dob);

      setValue("name", basicinfo?.name);
      setValue("email_id", basicinfo?.email_id);
      setValue("contact_number", basicinfo?.contact_number);
      setValue("gender", basicinfo?.gender);
      setValue("mother_tongue", basicinfo?.mother_tongue);
      setValue("preferred_language", basicinfo?.preferred_language);
      setValue("goal", basicinfo?.goal);
      let workhours = basicinfo?.work_hours?.split("-");
      if (workhours?.length > 0) {
        // setValue("work_hours_from", moment(workhours[0], "hh:mm a"));
        setValue("work_hours_from", dayjs(workhours[0], "hh:mm a"));
      }
      if (workhours?.length > 1) {
        // setValue("work_hours_to", moment(workhours[1], "hh:mm a"));
        setValue("work_hours_to", dayjs(workhours[1], "hh:mm a"));
      }
      // alert(basicinfo?.prefer_contact_times?.split(",") ?? [])

      setValue("prefer_contact_times", basicinfo?.prefer_contact_times ?? []);

      //  setValue("dob",dateval),
      // alert(basicinfo?.dob)

      var years = moment().diff(moment(basicinfo?.dob, "DD MMM YYYY"), "years");
      if (basicinfo?.dob) {
        setValue("dob", dateval);
      }
      setValue("age", years);
      setValue("profession_details", basicinfo?.profession_details);
      setValue("height", basicinfo?.height);
      setValue("current_weight", basicinfo?.current_weight);
      setValue("bmi", basicinfo?.bmi);
      setValue("city", basicinfo?.city);
      setValue("country", basicinfo?.country);
      setValue("address", basicinfo?.address);
      setValue("pincode", basicinfo?.pincode);
      setValue("instagram_ID", basicinfo?.instagram_ID);
      setValue("facebook_ID", basicinfo?.facebook_ID);

      setBMI(basicinfo?.bmi ?? "--");

      setInforview({
        Name: basicinfo?.name,
        "Email ID": basicinfo?.email_id,
        contact_number: basicinfo?.contact_number,
        gender:
          basicinfo?.gender === "M"
            ? "Male"
            : basicinfo?.gender == "F"
            ? "Female"
            : "Not to say",
        SourceID: basicinfo?.SourceID,
        dob: basicinfo?.dob,
        age: basicinfo?.age,
        work_hours: basicinfo?.work_hours,
        profession_details: basicinfo?.occupation,
        height: basicinfo?.height,
        current_weight: basicinfo?.current_weight,
        bmi: basicinfo?.bmi ?? "--",
        city: basicinfo?.city ?? "--",
        mother_tongue: basicinfo?.mother_tongue ?? "--",
        preferred_language: basicinfo?.preferred_language ?? "--",
        goal: basicinfo?.goal ?? "--",
        prefer_contact_times: basicinfo?.prefer_contact_times ?? [],
        address: basicinfo?.address ?? "--",
        country: basicinfo?.country ?? "--",
        pincode: basicinfo?.pincode ?? "--",
        account_name: basicinfo?.account_name ?? "--",
        instagram_ID: basicinfo?.instagram_ID ?? "--",
        facebook_ID: basicinfo?.facebook_ID ?? "--",
      });
    }
  }, [displaydata]);

  const handleCancel = (event) => {
    setIsedit(false);
  };

  const handleClick = (event) => {
    setIsedit(!isedit);
  };

  const handlePhonenumber = (e) => {
    const number = e.target.value.split(" ").slice(1).join("");

    if (number.length < 6 || number.length > 15) {
      toast.warn(
        "Please enter a valid Phone number between 6 and 15 characters",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setPhoneNumber(null);
    }
  };

  const handleWeight = (event) => {
    const currentWeight = event.target.value;
    if (currentWeight < 10 || currentWeight > 300) {
      toast.warn("Please enter a valid Weight between 10 and 300", {
        position: toast.POSITION.TOP_CENTER,
      });
      setWeight("");
    }
  };

  const handleSelectWeight = (e) => {
    setWeight(e.target.value);
  };

  const handleHieght = (e) => {
    setSelectedHeight(e.target.value);
    // setWeight("");
  };

  const handledietpreference = async (data) => {
    update({
      ...data,
      contact_number: phoneNumber.split(" ").join(""),
      height: selectedHeight,
      source: +selectedSource,
      bmi: bmi,
      current_weight: weight,
      mother_tongue: languageTwo,
      preferred_language: languageOne,
      prefer_contact_times:
        typeof selectedPrefferedTime === "string"
          ? [selectedPrefferedTime]
          : selectedPrefferedTime,
      occupation: profession,
      goal: selectedGoal,
      work_hour_from: data.work_hours_from,
      work_hour_to: data.work_hours_to,
      dob: data.dob,
      formtype: 1,
    });
  };
  const watchFields = watch(["height", "current_weight"]);

  const getHeightList = async () => {
    const hightResponse = await axiosFetch({
      url: `/getHeightValues`,
      method: "get",
    }).then((res) => res.data);

    const sourceResponse = await axiosFetch({
      url: `/getSources`,
      method: "get",
    }).then((res) => res.data);

    setHeight(hightResponse);
    setSource(sourceResponse);
  };

  useEffect(() => {
    getHeightList();
  }, []);

  function bmiCal() {
    if (weight && selectedHeight) {
      const heightInMeters = +selectedHeight / 100;
      const bmiValue = +weight / heightInMeters ** 2;
      setBMI(bmiValue.toFixed(2));
    }
  }

  useEffect(() => {
    bmiCal();
  }, [weight, selectedHeight]);

  // useEffect(() => {
  //   if (watchFields.length == 2 && watchFields[0] && watchFields[1]) {
  //     let weight = watchFields[1];
  //     let height = watchFields[0];
  //     let bmival = weight / ((height / 100) * (height / 100));
  //     setValue("bmi", bmival.toFixed(2));
  //   }
  // }, [watchFields]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          setIsedit(false);
          handledietpreference(data);
        })}
      >
        {masters?.profession && (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Client Name"}
                  name={"name"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter your client name",
                    },
                  }}
                  error={errors && errors.name ? true : false}
                  helperText={errors && errors.name ? errors.name.message : ""}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Name</Typography>
                  <Typography variant="h6">
                    {displaydata?.basicInfo?.name}
                  </Typography>
                </Stack>
              )}
            </Grid>

            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Email ID"}
                  name={"email_id"}
                  control={control}
                  rules={{
                    required: "Please enter client email id",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }}
                  error={errors && errors.email_id ? true : false}
                  helperText={
                    errors && errors.email_id ? errors.email_id.message : ""
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Email ID</Typography>
                  <Typography variant="h6">
                    {displaydata?.basicInfo?.email_id}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <MuiTelInput
                  sx={{
                    width: "100%",
                    height: "46px",
                    borderRadius: "6px",
                    border: "2px",
                    top: "0px",
                  }}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e);
                  }}
                  onBlur={handlePhonenumber}
                  defaultCountry="IN"
                />
              ) : (
                // <ControlledTextField
                //   label={"Phone Number"}
                //   name={"contact_number"}
                //   control={control}
                //   rules={{
                //     required: {
                //       value: true,
                //       message: "Please enter mobile number",
                //     },
                //   }}
                //   fullWidth={true}
                //   variant="outlined"
                //   error={errors && errors.contact_number ? true : false}
                //   helperText={
                //     errors && errors.contact_number
                //       ? errors.contact_number.message
                //       : ""
                //   }
                // />
                <Stack spacing={1}>
                  <Typography variant="caption">Mobile Number</Typography>{" "}
                  <Typography variant="h6">
                    {displaydata?.basicInfo?.contact_number}
                  </Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <ControlledRadioGroup
                  label={"Gender"}
                  name={"gender"}
                  control={control}
                  row={true}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[
                    {
                      value: "M",
                      label: "Male",
                    },
                    {
                      value: "F",
                      label: "Female",
                    },
                    {
                      value: "O",
                      label: "Not to say",
                    },
                  ]}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Gender</Typography>
                  <Typography variant="h6">
                    {displaydata?.basicInfo?.gender == "M"
                      ? "Male"
                      : displaydata?.basicInfo?.gender == "F"
                      ? "Female"
                      : "Others"}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledDatepicker
                  label={"Date of Birth"}
                  name={"dob"}
                  control={control}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Date of Birth</Typography>{" "}
                  <Typography variant="h6">
                    {infoview.dob ? `${infoview.dob}` : "--"}
                  </Typography>
                </Stack>
              )}
            </Grid>

            {!isedit && (
              <Grid item xs={3}>
                <Stack spacing={1}>
                  <Typography variant="caption">Age</Typography>{" "}
                  <Typography variant="h6">
                    {infoview.age ? `${infoview.age} years` : "--"}
                  </Typography>
                </Stack>
              </Grid>
            )}

            <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Height in cm
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleHieght}
                      value={selectedHeight}
                      label="Height in cm"
                    >
                      {height.map((value, index) => (
                        <MenuItem value={value.height_value} key={index}>
                          <div className="filter-date">
                            <span>{value.height_value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Height in cm</Typography>{" "}
                  <Typography variant="h6">{infoview?.height}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <TextField
                  id="outlined-basic"
                  label="Weight"
                  variant="outlined"
                  style={{ width: "100%" }}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 10,
                      max: 300,
                      // step: 0.1,
                    },
                  }}
                  value={weight}
                  onChange={handleSelectWeight}
                  onBlur={handleWeight}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Weight in kgs</Typography>
                  <Typography variant="h6">
                    {infoview.current_weight}
                  </Typography>
                </Stack>
              )}
            </Grid>

            <Grid item xs={3}>
              {isedit ? (
                <TextField
                  id="outlined-basic"
                  label="BMI"
                  variant="outlined"
                  style={{ width: "100%" }}
                  disabled
                  value={bmi}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">BMI</Typography>{" "}
                  <Typography variant="h6">{infoview.bmi}</Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Preferred language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setLanguageOne(e.target.value)}
                      value={languageOne}
                      label="1st preferred language"
                    >
                      {preferredLanguage.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <div className="filter-date">
                            <span>{value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Preferred language</Typography>{" "}
                  <Typography variant="h6">
                    {infoview.preferred_language}
                  </Typography>
                </Stack>
              )}
            </Grid>
            {/* <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      2nd preferred language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setLanguageTwo(e.target.value)}
                      value={languageTwo}
                      label="2nd preferred language"
                    >
                      {preferredLanguage.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <div className="filter-date">
                            <span>{value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">
                    2nd preferred language
                  </Typography>
                  <Typography variant="h6">{infoview.mother_tongue}</Typography>{" "}
                </Stack>
              )}
            </Grid> */}
            <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Goal</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSelectedGoal(e.target.value)}
                      value={selectedGoal}
                      label="Goal"
                    >
                      {goal.map((value, index) => (
                        <MenuItem value={value.name} key={index}>
                          <div className="filter-date">
                            <span>{value.name}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Goal</Typography>{" "}
                  <Typography variant="h6">{infoview?.goal}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Address"}
                  name={"address"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Address</Typography>{" "}
                  <Typography variant="h6">{infoview.address}</Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"City"}
                  name={"city"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">City</Typography>{" "}
                  <Typography variant="h6">{infoview.city}</Typography>
                </Stack>
              )}
            </Grid>

            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Country"}
                  name={"country"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Country</Typography>{" "}
                  <Typography variant="h6">{infoview.country}</Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Pincode"}
                  name={"pincode"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">pincode</Typography>{" "}
                  <Typography variant="h6">{infoview.pincode}</Typography>{" "}
                </Stack>
              )}
            </Grid>

            <Grid item xs={3} container direction={"row"} spacing={2}>
              {isedit ? (
                <>
                  <Grid item xs={6}>
                    <ControlledTimepicker
                      label={"Work Timings From"}
                      name={"work_hours_from"}
                      control={control}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ControlledTimepicker
                      label={"Work Timings to"}
                      name={"work_hours_to"}
                      control={control}
                      rules={{}}
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {" "}
                  <Stack spacing={1}>
                    <Typography variant="caption">Working Hours</Typography>{" "}
                    <Typography variant="h6">
                      {infoview.work_hours ?? "--"}
                    </Typography>{" "}
                  </Stack>{" "}
                </Grid>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Account Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSelectedSource(e.target.value)}
                      value={selectedSource}
                      label="Account Name"
                    >
                      {source.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          <div className="filter-date">
                            <span>{value.Source}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Account Name</Typography>{" "}
                  <Typography variant="h6">{infoview.SourceID}</Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                // <ControlledAutocomplete
                //   label={"Best Time to call you"}
                //   name={"prefer_contact_times"}
                //   control={control}
                //   rules={{}}
                //   fullWidth={true}
                //   variant="outlined"
                //   multiple={true}
                //   //  defaultValue={['8 AM to 9 AM']}
                //   defaultValue={
                //     displaydata?.basicInfo?.prefer_contact_times ?? []
                //   }
                //   options={besttimetocallmaster}
                // />

                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Best Time to call you
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSelectedPrefferedTime(e.target.value)}
                      value={selectedPrefferedTime}
                      label=" Best Time to call you"
                    >
                      {besttimetocallmaster.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <div className="filter-date">
                            <span>{value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">
                    Preferred Contact Time
                  </Typography>
                  {infoview?.prefer_contact_times && (
                    <Stack direction="row" spacing={1}>
                      {infoview?.prefer_contact_times?.map((itm) => (
                        <Chip label={itm} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Grid>

            <Grid item xs={3}>
              {isedit ? (
                <TextField
                  id="outlined-basic"
                  label="Profession"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Profession Details</Typography>{" "}
                  <Typography variant="h6">
                    {infoview.profession_details}
                  </Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  multiple={true}
                  label={"Instagram ID"}
                  name={"instagram_ID"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[]}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Instagram ID</Typography>{" "}
                  <Typography variant="h6">{infoview.instagram_ID}</Typography>{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Facebook Link/id"}
                  name={"facebook_ID"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Facebook ID</Typography>{" "}
                  <Typography variant="h6">{infoview.facebook_ID}</Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit ? (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type={"submit"}>
                {" "}
                Save{" "}
              </Button>{" "}
              :
            </Stack>
          ) : (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="contained" onClick={handleClick}>
                {" "}
                Edit{" "}
              </Button>{" "}
            </Stack>
          )}
        </Grid>
      </form>
    </>
  );
};

export default BasicInformation;

//btn-disabled
