import Modal from "../../../components/Modal/Modal";

import "../Report.css";

const LiveSesionModal = ({ isOpen, liveSessionDetails, onCloseLiveModal }) => {
  console.log(liveSessionDetails, isOpen, "test");

  return (
    <Modal isOpen={isOpen} onClose={onCloseLiveModal}>
      <div className="live_session_modal">
        {liveSessionDetails.length > 0 &&
          liveSessionDetails.map((session, index) => (
            <div key={index} className="live_session_items">
              <h1>Live Session {++index}</h1>
              <div>
                <span> Session Date</span>
                <span>{session.start_date}</span>
              </div>
              <div>
                <span>Start Time</span>
                <span>{session.start_time}</span>
              </div>
              <div>
                <span>Session Type</span>
                <span>{session.type}</span>
              </div>
              <div>
                <span>Session Name</span>
                <span>{session.session_name}</span>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default LiveSesionModal;
