import { FormHelperText, TextField } from "@mui/material";

import { Controller } from "react-hook-form";

const TextBox = ({ control, name, isRequired, disabled }) => {
  return (
    <Controller
      name={name}
      rules={{ required: isRequired ? "required" : false }}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            variant="outlined"
            fullWidth
            size="small"
            error={!!error}
            disabled={disabled}
          />
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        </>
      )}
    />
  );
};

export default TextBox;
