import React, { useState, useEffect, useContext } from "react";
import * as $ from "jquery";
import ReactDOM from "react-dom";
import ClientProfile from "./ClientProfile";
import SideBarItem from "../../components/SideBarItem";
import Accordion from "react-bootstrap/Accordion";
import ClientPlans from "./ClientPlans copy";
import ClientProgress from "./ClientProgress";
import ClientPayments from "./ClientPayments";
import ClientBookings from "./ClientBookings";
import ClientDiary from "./ClientDiary";
import { makeStyles } from "@material-ui/core/styles";

import ClientDocuments from "./ClientDocuments";
import { toast } from "react-toastify";
import axios from "axios";
import "./ClientDetail.css";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { PlivoContext } from "../../context/PlivoContext";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ClientNotesSection from "./ClientNotesSection";
import ProgramDetails from "./ProgramDetails";
import ClientTestimonials from "./ClientTestimonials";
import CallLog from "./CallLog";
import Retention from "./Retention";
import Interaction from "../Leads/Interaction/Interaction";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CallPopup from "../Call/CallPopup";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import ProgramList from "../Leads/Programs/ProgramList";
import PaymentList from "../Leads/Payments/PaymentList";
import NoteList from "../Leads/Notes/NoteList";
import Progress from "../Leads/Progress/Progress";
import HFReportList from "../Leads/HF_Report/HFReportList";
import ProgramDashborad from "../Leads/Programs/ProgramDashborad";

import TeleCall from "../Call/TeleCall";
import History from "../NewClientLeadTable/components/History";

import Servetel from "../Call/Servetel";

import "./ClientDetail.css";
import { Card, Paper } from "@material-ui/core";
import { Favorite, FavoriteBorder, Phone } from "@material-ui/icons";

import { useCallerPopup } from "../store/Call/CallerPopupContext";

function ClientDetail(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 700,
      width: "100%",
    },
    labelContainer: {
      width: "auto",
      padding: 0,
    },
    iconLabelWrapper: {
      flexDirection: "row",
    },
    tabs: {
      width: "100%",
      background: "white",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const { setIsVisible, isVisible, setPendingInbound } = useCallerPopup();

  const location = useLocation();
  const pathName = location.pathname.includes("lead") ? "lead" : "client";
  const selectedRowDetails = location.state;
  const pendingInbound = selectedRowDetails?.pendingInbound;

  const contextState = useContext(stateContext);
  const { interactionForm, setInteractionForm } = useContext(PlivoContext);
  const { configToken, featureAccessible } = contextState.state;
  const [state, setState] = useState({
    newTableRow: {},
    editingActivated: false,
    editingID: "",
  });

  const [sidetab, setSidetab] = React.useState(0);
  const [profileinformation, setProfileinformation] = React.useState({});

  const handleChange = (event, newValue) => {
    setSidetab(newValue);
  };

  const [showServetel, setShowServetel] = useState(false);

  const [profileDtls, setProfileDtls] = useState(null);
  const [basicInfo, setBasicInfo] = useState(null);

  const routeParams = useParams();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [callFlag, setCallFlag] = useState(false);
  const [callType, setCallType] = useState("");

  const [isBackDrop, setIsBackDrop] = useState(false);

  const convertUtcToLocalTime = (utc) => {
    let inputDate;
    let formattedDate;

    if (utc) {
      const utcTimestamp = utc;
      const dateInUTC = new Date(utcTimestamp);
      const localTime = dateInUTC.toLocaleString();
      inputDate = localTime.split(",");

      const [month, day, year] = inputDate[0].split("/");
      formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
    } else {
      return [];
    }

    return [formattedDate, inputDate];
  };

  useEffect(() => {
    if (pendingInbound) {
      setIsVisible(true);
      setPendingInbound(selectedRowDetails);
    }
  }, [pendingInbound]);

  useEffect(() => {
    if (isBackDrop) {
      // $("#menu_section").hide();
      // $("#menu_section").css({
      //   "z-index": "0",
      // });
    } else {
      // $("#menu_section").show();
      // $("#menu_section").css({
      //   "z-index": "1",
      // });
    }
  }, [isBackDrop]);

  const manageCallFlag = () => {
    if (location !== null && location !== undefined) {
      if (location.state !== null && location.state !== undefined) {
        if (location.state.tableValue !== undefined) {
          if (location.state.callType) {
            setCallType(location.state.callType);
          }
          setInteractionForm({
            ...interactionForm,
            open: true,
            details: {
              callType: location?.state?.callType ?? "",
              callFlag: true,
              leadId: routeParams?.id,
            },
          });
          // setActivePage("Call History");
          setCallFlag(true);
        }
      } else {
        setCallFlag(false);
      }
    }
  };

  const [isSaveInfo, setIsSaveInfo] = useState(false);

  useEffect(() => {
    //Get All Clients
    manageCallFlag();
    axiosFetch({
      method: "get",
      url: `/getProfileDetailsById/` + routeParams.id,
    })
      .then((response) => {
        let clientsData = response.data;
        if (response.data) {
          setProfileinformation(response.data);
          if (response.data.basicInfo !== undefined) {
            let basic_info = response.data.basicInfo;
            basic_info.id = response.data.id;
            // basic_info.Lead = response.data.Lead;
            localStorage.setItem("currentProfile", JSON.stringify(basic_info));
            setBasicInfo(basic_info);
          }
        }
        setState((prevState) => ({ ...prevState, newTableRow: clientsData }));
        clientsData.LeadID.Payments = clientsData.LeadID
          ? clientsData.LeadID.lead_payments
          : [];
        clientsData.LeadID.Payments.map((payment) => {
          if (payment.product !== null) {
            payment.ProductId = payment.product.product_details.id;
            payment.ProductName = payment.product.product_details.name;
          }
        });
        setActivePage("ClientProfile");

        axios
          .get(
            `${ATLAS_URI}/getProfileImageByLeadClient/${clientsData.Lead}`,
            configToken
          )
          .then((imageResponse) => {
            let image = imageResponse.data;
            setState((prevState) => ({
              ...prevState,
              newTableRow: { ...clientsData, ProfileImage: image },
            }));
          });
      })
      .catch((err) => console.error(err));
    setIsSaveInfo(false);
  }, [isSaveInfo]);

  const change = (val) => {
    setActivePage(val);
    manageCallFlag();
  };

  //   useEffect(() => {
  //     trainerAndDietitianName()
  //   }, [])

  //   const trainerFirstName=state.newTableRow.ClientTrainerInfo?(state.newTableRow.ClientTrainerInfo.firstName?state.newTableRow.ClientTrainerInfo.firstName:'-'):'-'
  //   const trainerLastName=state.newTableRow.ClientTrainerInfo?(state.newTableRow.ClientTrainerInfo.lastName&&state.newTableRow.ClientTrainerInfo.lastName):'-'
  //   const dietitianFirstName=state.newTableRow.ClientDietitianInfo?(state.newTableRow.ClientDietitianInfo.firstName?state.newTableRow.ClientDietitianInfo.firstName:'-'):'-'
  //   const dietitianLastName=state.newTableRow.ClientDietitianInfo?(state.newTableRow.ClientDietitianInfo.lastName&&state.newTableRow.ClientDietitianInfo.lastName):'-'

  const initialPage = window.localStorage.getItem(
    "goto_clients_workoutplan_page"
  )
    ? "ClientPlans"
    : "ClientProfile";
  const [activePage, setActivePage] = useState(initialPage);
  const history = useHistory();

  const current_route = window.location.pathname;
  let [showMenu, setShowMenu] = useState(false);

  const goBackToPreviousPage = () => {
    if (current_route.includes("/lead")) {
      localStorage.setItem("RESTORE_LEAD_LISTING_SCREEN_GRID", true);
      localStorage.setItem("RESTORE_LEAD_LISTING_SCREEN_PANEL", true);
      //history.push("/Leads/listLeads");
    } else {
      // localStorage.setItem("RESTORE_CLIENT_LISTING_SCREEN", true);
      //history.push("/Clients/listClients");
    }

    // localStorage.setItem("backButton", true);

    const preFilter = localStorage.getItem("currentFilter");

    if (preFilter !== null) {
      localStorage.removeItem("currentPageNo");
    }

    // history.push(localStorage.getItem("backToTable"), {
    //   LeadListDate: true,
    //   isCurrentFilter: "filter",
    // });

    // New Filter lead and client table variables

    history.push(localStorage.getItem("backToTable"));

    let isSearchValue = localStorage.getItem("ClientLeadGlobalSearch");

    if (isSearchValue) {
      localStorage.setItem("testSearch", true);
    }

    localStorage.setItem("RESTORE_CLIENT_LISTING_SCREEN", true);
  };
  const toggleMenus = () => {
    if (current_route.includes("/lead")) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const makeCall = () => {
    // if (current_route.includes("/lead")) {
    //   setIsLead(true);
    // } else {
    //   setIsShowPopup(true);
    // }
    setIsShowPopup(true);
    setInteractionForm({
      ...interactionForm,
      open: true,
      details: {
        callType: location?.state?.callType ?? "",
        callFlag: true,
        leadId: routeParams?.id,
      },
    });

    // setActivePage("Call History");
    setCallFlag(true);

    // manageCallFlag();
  };

  const closePopup = (close) => {
    if (close) {
      setCallFlag(false);
    }

    setIsShowPopup(false);
    setIsLead(false);
  };

  const handleOutsideClick = () => {
    if (isBackDrop) {
      toast.warn("You cannot navigate to other pages during a call", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    toggleMenus();
  }, []);

  useEffect(() => {
    if (isVisible) {
      setIsVisible(isVisible);
    }
  }, []);

  return (
    <div className="mx-2">
      <div
        className={isBackDrop ? "backdrop-form" : ""}
        onClick={handleOutsideClick}
      ></div>
      <Button
        // className="test"
        onClick={goBackToPreviousPage}
        variant="outlined"
        sx={{ margin: "8px 12px" }}
      >
        <ArrowBackOutlinedIcon /> Back
      </Button>
      {showMenu ? (
        <div className="box box-primary ">
          <section className="row topProfile">
            <div className="col-md-2 profile-image">
              <img src={state.newTableRow.ProfileImage} alt="profile" />
            </div>

            <div className="info col-md-9">
              <div className="row">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="col-auto">
                    <h2>{basicInfo !== null ? basicInfo?.name : "-"}</h2>
                  </div>
                  <div
                    className="col-auto "
                    // style={{ position: "relative", left: "70%" }}
                  >
                    <button
                      className="btn btn-info mr-5"
                      style={{
                        width: "100px",
                        backgroundColor: " #5D9C59",
                      }}
                      onClick={makeCall}
                    >
                      Plivo
                    </button>

                    <button
                      className="btn btn-info ml-5"
                      style={{ width: "100px", backgroundColor: " #5D9C59" }}
                      onClick={() => setShowServetel(true)}
                    >
                      Servetel
                    </button>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      ID
                      <span>
                        {basicInfo?.ClientInfo?.id !== null
                          ? basicInfo?.ClientInfo?.id
                          : "-"}
                      </span>
                    </p>

                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Account Name
                      <span>
                        {!!basicInfo?.SourceID ? basicInfo?.SourceID : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Email ID{" "}
                      <span>
                        {!!basicInfo?.email_id ? basicInfo?.email_id : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Phone No
                      <span>
                        {!!basicInfo?.contact_number
                          ? basicInfo?.contact_number
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>
                      Program
                      <span>
                        {basicInfo?.lead_product_details !== null
                          ? basicInfo?.lead_product_details
                          : "-"}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="offset-md-3 col-md-4">
                  <div className="row">
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Nutritionist{" "}
                      <span>
                        {basicInfo?.ClientInfo?.ClientDietitianInfo
                          ? basicInfo?.ClientInfo?.ClientDietitianInfo
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Fitness Coach
                      <span>
                        {" "}
                        {basicInfo?.ClientInfo?.ClientTrainerInfo
                          ? basicInfo?.ClientInfo?.ClientTrainerInfo
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Status
                      <span>
                        {state.newTableRow.Status
                          ? state.newTableRow.Status
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Created Date
                      <span>
                        {convertUtcToLocalTime(basicInfo?.createdAt)[0]}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Created By{" "}
                      <span>
                        {basicInfo?.created_by_details
                          ? basicInfo?.created_by_details
                          : "-"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="box box-primary ">
          <section className="row topProfile">
            <div className="col-md-2 profile-image">
              <img src={state.newTableRow.ProfileImage} alt="profile" />
            </div>

            <div className="info col-md-9">
              <div className="row">
                <div className="row">
                  <div className="col-auto">
                    <h2 style={{ textTransform: "capitalize" }}>
                      {basicInfo !== null ? basicInfo?.name : "-"}
                    </h2>
                  </div>
                  <div
                    className="col-auto "
                    style={{
                      position: "relative",
                      left: "50%",
                    }}
                  >
                    <button
                      className="btn btn-info mr-5"
                      style={{
                        width: "100px",
                        backgroundColor: " #5D9C59",
                      }}
                      onClick={makeCall}
                    >
                      Plivo
                    </button>
                    {pathName === "lead" && (
                      <button
                        className="btn btn-info ml-5"
                        style={{ width: "100px", backgroundColor: " #5D9C59" }}
                        onClick={() => setShowServetel(true)}
                      >
                        Servetel
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row">
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Lead ID
                      <span>{basicInfo !== null ? basicInfo?.id : "-"}</span>
                    </p>

                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Phone No
                      <span>
                        {!!basicInfo?.contact_number
                          ? basicInfo?.contact_number
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Email ID{" "}
                      <span>
                        {!!basicInfo?.email_id ? basicInfo?.email_id : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Account Name
                      <span>
                        {!!basicInfo?.SourceID ? basicInfo?.SourceID : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Assigned To{" "}
                      <span>
                        {basicInfo?.assigned_to_user
                          ? basicInfo?.assigned_to_user
                          : "-"}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="offset-md-3 col-md-4">
                  <div className="row">
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>
                      Stage
                      <span>
                        {basicInfo?.Lead?.stage ? basicInfo?.Lead?.stage : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>
                      Disposition
                      <span>
                        {basicInfo?.Lead?.disposition
                          ? basicInfo?.Lead?.disposition
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>
                      Sub Disposition
                      <span>
                        {basicInfo?.Lead?.subDisposition
                          ? basicInfo?.Lead?.subDisposition
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Created date
                      <span>{basicInfo?.createdAt.split("T")[0]}</span>
                    </p>
                    <p>
                      <span style={{ position: "relative", right: "250px" }}>
                        :
                      </span>{" "}
                      Created By <span>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div className="clientSidebar ">
            <Accordion>
              <div onClick={() => setActivePage("ClientProfile")}>
                <SideBarItem
                  itemKey="0"
                  title="Profile"
                  // icon="fas fa-user"
                  data="9"
                />
              </div>
              {featureAccessible.find(
                (fe) =>
                  (fe.sub_feature_id == 23 && fe.is_view) ||
                  (fe.sub_feature_id == 24 && fe.is_view)
              ) && (
                <div onClick={() => change("Call History")}>
                  <SideBarItem
                    itemKey="0"
                    title="Interaction"
                    // icon="fas fa-analytics"
                    data="10"
                  />
                </div>
              )}

              {featureAccessible.find(
                (fe) =>
                  (fe.sub_feature_id == 23 && fe.is_view) ||
                  (fe.sub_feature_id == 24 && fe.is_view)
              ) && (
                <div onClick={() => setActivePage("ClientPrograms")}>
                  <SideBarItem
                    itemKey="0"
                    title="Programs"
                    icon="fa fa-briefcase"
                    data="2"
                  />
                </div>
              )}

              {featureAccessible.find(
                (fe) =>
                  fe.feature_id === 14 && fe.sub_feature_id === 15 && fe.is_view
              ) !== undefined && (
                <div onClick={() => setActivePage("ClientPayments")}>
                  <SideBarItem
                    itemKey="0"
                    title="Payments"
                    // icon="fa fa-credit-card "
                    data="3"
                  />
                </div>
              )}

              <div onClick={() => setActivePage("ClientNotes")}>
                <SideBarItem
                  itemKey="0"
                  title="Notes"
                  icon="fas fa-sticky-note"
                  data="4"
                />
              </div>

              {featureAccessible.find(
                (fe) =>
                  (fe.sub_feature_id == 23 && fe.is_view) ||
                  (fe.sub_feature_id == 24 && fe.is_view)
              ) && (
                <div onClick={() => setActivePage("ClientPlans")}>
                  <SideBarItem
                    itemKey="0"
                    title="Plans"
                    icon="fas fa-tasks"
                    data="5"
                  />
                </div>
              )}

              {featureAccessible.find(
                (fe) => fe.sub_feature_id == 34 && fe.is_view
              ) && (
                <div onClick={() => change("ClientProgress")}>
                  <SideBarItem
                    itemKey="0"
                    title="Progress"
                    // icon="fa fa-line-chart"
                    data="6"
                  />
                </div>
              )}

              {featureAccessible.find(
                (fe) => fe.sub_feature_id == 34 && fe.is_view
              ) && (
                <div onClick={() => change("History")}>
                  <SideBarItem
                    itemKey="0"
                    title="History"
                    // icon="fa fa-line-chart"
                    data="12"
                  />
                </div>
              )}

              {
                // !showMenu &&
                featureAccessible.find(
                  (fe) => fe.sub_feature_id == 34 && fe.is_view
                ) && (
                  <div onClick={() => change("HFReport")}>
                    <SideBarItem itemKey="0" title="H&F Report" data="11" />
                  </div>
                )
              }

              {showMenu &&
                featureAccessible.find(
                  (fe) =>
                    (fe.sub_feature_id == 23 && fe.is_view) ||
                    (fe.sub_feature_id == 24 && fe.is_view)
                ) && (
                  <div onClick={() => change("Testimonials")}>
                    <SideBarItem
                      itemKey="0"
                      title="Testimonials"
                      // icon="fas fa-analytics"
                      data="7"
                    />
                  </div>
                )}

              {showMenu &&
                featureAccessible.find(
                  (fe) =>
                    (fe.sub_feature_id == 23 && fe.is_view) ||
                    (fe.sub_feature_id == 24 && fe.is_view)
                ) && (
                  <div onClick={() => change("Retention")}>
                    <SideBarItem
                      itemKey="0"
                      title="Retention"
                      icon="fas fa-analytics"
                      data="8"
                    />
                  </div>
                )}
            </Accordion>
          </div>

          {/* <Tabs 
                  orientation="vertical"
                  variant="scrollable"
                  value={sidetab}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  >
                 
                  <Tab  label="Profile"  icon={<FavoriteBorder />} iconPosition="start"/>
                  <Tab label="Item Three" icon={<FavoriteBorder />} iconPosition="start" />
                  <Tab label="Item Four" icon={<FavoriteBorder />} iconPosition="start" />
                  <Tab label="Item Five" icon={<FavoriteBorder />} iconPosition="start" />
                  <Tab label="Item Six" icon={<FavoriteBorder />} iconPosition="start" />
                  <Tab label="Item Seven" icon={<FavoriteBorder />} iconPosition="start" />
            </Tabs> */}
        </Grid>
        <Grid item xs={10}>
          {activePage === "ClientProfile" && (
            <ClientProfile
              setIsSaveInfo={setIsSaveInfo}
              profileinfo={profileinformation}
              state={state?.newTableRow?.basicInfo}
              dietPreference={state?.newTableRow?.dietPreference}
              workoutPreference={state?.newTableRow?.workoutPreferences}
              medicalAssessment={state?.newTableRow?.medicalAssessment}
            />
          )}
          {activePage === "ClientNotes" && <NoteList />}
          {activePage === "ClientPlans" && (
            <ClientPlans clientsData={state.newTableRow} state={props} />
          )}
          {activePage === "ClientProgress" && <Progress />}
          {activePage === "History" && <History />}
          {activePage === "HFReport" && <HFReportList />}
          {activePage === "ClientPayments" && (
            <PaymentList state={state.newTableRow} />
          )}
          {activePage === "CallLog" && <CallLog />}
          {activePage === "ClientBookings" && <ClientBookings state={props} />}
          {activePage === "ClientDiary" && <ClientDiary state={props} />}
          {activePage === "ClientDocuments" && (
            <ClientDocuments state={props} />
          )}
          {activePage === "ClientPrograms" && (
            <ProgramDashborad lead_id={state.newTableRow.Lead} />
          )}
          {activePage === "Testimonials" && <ClientTestimonials />}
          {activePage === "Retention" && <Retention />}
          {activePage === "Call History" && (
            <Interaction
              className="test"
              callFlag={callFlag}
              callType={callType}
              setCallFlag={setCallFlag}
              onSetIsBackDrop={() => null}
            />
          )}
        </Grid>
      </Grid>
      {/* {isLead && <TeleCall close={closePopup} selectedRow={basicInfo} />} */}

      {isShowPopup ? (
        <CallPopup
          close={closePopup}
          selectedRow={basicInfo}
          callType={"plivo"}
        />
      ) : (
        ""
      )}

      <Servetel
        isOpen={showServetel}
        selectedRow={basicInfo}
        leadId={basicInfo?.id}
        onClose={() => setShowServetel(false)}
      />
    </div>
  );
}

export default ClientDetail;
