import React, { useState, useEffect, useContext } from "react";
import * as $ from "jquery";
import axios from "axios";
import BoxHeader from "../../components/BoxHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { MdDeleteOutline } from "react-icons/md";
import GridTableClientPlans from "./GridTableClientPlans";
import moment from "moment";
import ClientPlansPopUp from "./ClientPlansPopUp";
import Popupmodel from "../Leads/Popupmodel";
import "./ClientPlans.css";
import { ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import ClientWorkoutPlanListing from "./ClientWorkoutPlanListing";
import { BiArrowBack } from "react-icons/bi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { HiPencilAlt } from "react-icons/hi";
import Index from "../../Utlis/Text Editor/Index";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useSelector } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClientDietPlansMenuTable from "./ClientDietPlansMenuTable";
import { DatePicker } from "@mui/x-date-pickers";

function ClientNotes(props) {
  const [editMealTarget, setEditMealTarget] = useState(false);

  const [disableSaveBtn, handleDisableSaveBtn] = useState(false);
  const [mainPlanNotes, setMainPlanNotes] = useState("");
  const [InstructionNote, SetInstructionNote] = useState("");
  const [edit, setEdit] = useState(false);
  let Initialfood = [
    [
      {
        macronutrients: {
          calories: null,
          protein: 0,
          carbs: 0,
          fibre: 0,
          fats: 0,
        },
        micronutrients: {
          IRON_mg: 0,
          ZINC_mg: 0,
          NIACIN_mg: 0,
          CALCIUM_mg: 0,
          SELENIUM_mg: 0,
          THIAMINE_mg: 0,
          MAGNESIUM_mg: 0,
          MANGANESE_mg: 0,
          POTASSIUM_mg: 0,
          VITAMIN_A_mg: 0,
          VITAMIN_C_mg: 0,
          VITAMIN_D_mg: 0,
          VITAMIN_E_mg: 0,
          VITAMIN_K_mg: 0,
          FOLIC_ACID_mg: 0,
          PHOSPHORUS_mg: 0,
          RIBOFLAVIN_mg: 0,
        },
        id: "",
        Typ: "",
        unit: "",
        Category: "",
        Quantity: 0,
        quantity: 0,
        food_item: "",
        defaultValue: 0,
        Units_gms_Ml_Piece: "",
        food_instructions: "",
      },
    ],
  ];
  let Initialmenu = [
    {
      menuName: "",

      calories: null,
      food: Initialfood,
    },
  ];

  let initalState = {
    food_meals: [
      {
        mealName: "Early Morning",
        active: false,
        foodMenues: Initialmenu,
        time: "06:00",
        targetCalories: 0,
      },
      {
        mealName: "Breakfast",
        active: false,
        foodMenues: Initialmenu,
        time: "09:00",
        targetCalories: 0,
      },
      {
        mealName: "Mid Morning",
        active: false,
        foodMenues: Initialmenu,
        time: "11:00",
        targetCalories: 0,
      },
      {
        mealName: "Lunch",
        active: false,
        foodMenues: Initialmenu,
        time: "13:00",
        targetCalories: 0,
      },
      {
        mealName: "Evening Snack",
        active: false,
        foodMenues: Initialmenu,
        time: "17:00",
        targetCalories: 0,
      },
      {
        mealName: "Dinner",
        active: false,
        foodMenues: Initialmenu,
        time: "20:00",
        targetCalories: 0,
      },
      // {
      //   mealName: "Supper",
      //   active: false,
      //   foodMenues: Initialmenu,
      //   time: "22:00",
      //   targetCalories: 0,
      // },
    ],
  };

  const [state, setState] = useState(initalState);
  const [time, setTime] = useState("");

  const handleAddMenu = (mealIdx) => {
    setState((state) => {
      let newState = JSON.parse(JSON.stringify(state));
      let menus = newState.food_meals[mealIdx].foodMenues;
      menus.push({ food: [], totalCalories: null, menuName: "" });
      newState.food_meals[mealIdx].foodMenues = menus;
      return newState;
    });
  };
  const handleMealDelete = (e, name) => {
    const updatedMeals = state.food_meals.map((item) =>
      item.mealName === name
        ? { ...item, active: false, foodMenues: Initialmenu }
        : item
    );
    setState({ ...state, food_meals: updatedMeals });
  };

  function updateMealcalandtime(e, mealIdx, key) {
    setState((state) => ({
      ...state,
      food_meals: state.food_meals.map((meal, Idx) => {
        if (Idx == mealIdx) {
          if (key === "time") {
            const newTime = moment(e.target.value, "HH:mm").format("hh:mm A");
            meal[key] = newTime;
          } else {
            meal[key] = e.target.value;
          }
        }
        return meal;
      }),
    }));
  }

  function updateItem(mealIdx, menuIdx, andIdx, orIdx) {
    let foodItem =
      state.food_meals[mealIdx].foodMenues[mealIdx].food[andIdx][orIdx];
    //update food item as per change
    state.food_meals[mealIdx].foodMenues[menuIdx].food[andIdx][orIdx] =
      foodItem;
    return state;
  }

  const handleTimeChange = (event) => {
    let hour = event.target.value.split(":")[0];
    let min = event.target.value.split(":")[1];
    let suffix = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;

    setTime(event.target.value);
  };

  // const clientId = useSelector((state) => state.leadId);
  const { id } = useParams();
  let clientId = id;

  const [clientData, setClientData] = useState({});
  const [mealBox, setMealBox] = useState(false);

  const [minimize, setMinimize] = useState(false);
  const [minimizemeal, setMinimizemeal] = useState(false);
  const [targetCalories, setTargetCalories] = useState(null);
  const [targetProtein, setTargetProtein] = useState(null);
  const [targetFats, setTargetFats] = useState(null);
  const [targetCarbs, setTargetCarbs] = useState(null);
  const [targetFibre, setTargetFibre] = useState(null);
  const [waterTarget, setWaterTarget] = useState(null);

  let age = clientData.basicInfo?.age;

  let height;

  if (
    clientData?.basicInfo?.height != null &&
    clientData?.basicInfo?.height_unit != null
  ) {
    if (clientData?.basicInfo?.height_unit == 1) {
      height = String(clientData?.basicInfo?.height);

      const [feetPart, inchesPart] = height
        .split("'")
        .map((part) => parseInt(part, 10));

      height = (feetPart * 30.48 + inchesPart * 2.54).toFixed(0);
    } else height = clientData?.basicInfo?.height;
  } else height = 0;

  let weight;

  if (
    clientData?.basicInfo?.current_weight != null &&
    clientData?.basicInfo?.weight_unit != null
  ) {
    if (clientData?.basicInfo?.weight_unit == 1) {
      weight = (clientData?.basicInfo?.current_weight * 0.4535).toFixed(0);
    } else {
      weight = clientData?.basicInfo?.current_weight;
    }
  } else weight = 0;

  useEffect(() => {
    let bmr = 0;
    if (clientData?.basicInfo?.gender == "M") {
      if (height !== 0 && weight !== 0)
        bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      else bmr = "No Data";
    } else if (clientData?.basicInfo?.gender == "F") {
      if (height !== 0 && weight !== 0)
        bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      else bmr = "No Data";
    } else {
      bmr = "No Data";
    }

    let activityLevelChart = {
      "Little to No Activity": 1.2,
      "Lightly Active": 1.375,
      "Moderately Active": 1.55,
      "Very Active": 1.725,
      "Extra Active": 1.9,
    };

    const activityLevelValue = activityLevelChart[
      clientData?.workoutPreferences?.active_you_are ||
        clientData?.workoutPreferences?.active_you_are?.title
    ]
      ? activityLevelChart[
          clientData?.workoutPreferences?.active_you_are ||
            clientData?.workoutPreferences?.active_you_are?.title
        ]
      : 1;

    // Calculate TDEE - Total Daily Energy Expenditure = Target Calories
    if (bmr != "No Data" && !edit) {
      const tdee = bmr * activityLevelValue;

      setTargetCalories(tdee.toFixed(0));
      setTargetProtein(clientData?.basicInfo?.current_weight);
      setTargetCarbs(((0.6 * tdee) / 4).toFixed(0));
      setTargetFibre(35);
      setTargetFats(((0.2 * tdee) / 9).toFixed(0));
    } else if (!edit) {
      const tdee = bmr * activityLevelValue;

      setTargetCalories(null);
      setTargetProtein(null);
      setTargetCarbs(null);
      setTargetFibre(null);
      setTargetFats(null);
    }
  }, [clientData]);

  useEffect(() => {
    //Get All Clients
    axios
      .get(`${ATLAS_URI}/getProfileDetailsById/` + clientId, configToken)
      .then((response) => {
        setClientData(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const [showMealComponent, setShowMealComponent] = useState(false);

  const handleMealClick = (index, each) => {
    const updatedMeals = state.food_meals.map((item) =>
      item.mealName === each.mealName ? { ...item, active: true } : item
    );

    setState({ ...state, food_meals: updatedMeals });
  };

  const params = useParams();

  const [quantity, setQuantity] = useState([]);
  const [childRow, setchildRow] = useState();
  const [childRowId, setChildRowId] = useState();
  const [
    displayErrorForSameDropdownValue,
    setDisplayErrorForSameDropdownValue,
  ] = useState(false);

  let hasEmptyQuantity = false;

  const [dietGrid, setDietGrid] = useState([
    {
      newTableRow: {
        isRepeat: false,

        id: 0,
        value: dayjs("2018-01-01T00:00:00.000Z"),

        addValue: [
          "Early Morning",
          "Breakfast",
          "Mid Morning",
          "Lunch",
          "Evening Snacks",
          "Dinner",
        ],
        foodInTable: [],
        dropDownValue: "Early Morning",
        timePick: new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }),

        quantity: null,
        total: [],
        note: "",
        targetCalorie: "",
      },
      updateRow: {
        value: "",
        addValue: [
          "Early Morning",
          "Breakfast",
          "Mid Morning",
          "Lunch",
          "Evening Snacks",
          "Dinner",
        ],
        foodInTable: [],
      },
    },
  ]);

  let history = useHistory();

  const contextState = useContext(stateContext);
  const { configToken, operator } = contextState.state;

  let days = [];
  let daysRequired = 30;
  const [show, setshow] = useState(false);
  let addMeal = (id) => {
    setcheckids(id);
    setshow(true);
  };
  for (let i = 1; i <= daysRequired; i++) {
    days.push(moment().add(i, "days").format(" D MMMM-ddd"));
  }

  const [childGridId, setchildGridId] = useState();

  const CreateRepeatTable = (data) => {
    setDietGrid((prevState) => {
      let newTableRow = {
        id: parseInt(data.newTableRow.id) + 1,

        isRepeat: true,

        value: dayjs("2018-01-01T00:00:00.000Z"),

        addValue: data.newTableRow.addValue,
        foodInTable: [],

        timePick: data.newTableRow.timePick,

        dropDownValue: data.newTableRow.dropDownValue,

        targetCalorie: "",
        quantitySelected: [],
      };

      let array = [];

      array.push({ newTableRow });

      prevState = prevState.map((prev) => {
        if (prev.newTableRow.id >= data.newTableRow.id + 1) {
          prev.newTableRow.id = prev.newTableRow.id + 1;
        }

        return prev;
      });

      return [...prevState, ...array];
    });
  };

  const CreateTable = () => {
    let customizedMealList;

    let list_meal = [
      "Early Morning",
      "Breakfast",
      "Mid Morning",
      "Lunch",
      "Evening Snacks",
      "Dinner",
    ];

    if (dietGrid.length >= 2) {
      customizedMealList = dietGrid[
        dietGrid.length - 1
      ].newTableRow.addValue.filter(
        (item) =>
          item !== dietGrid[dietGrid.length - 1].newTableRow.dropDownValue
      );
    } else {
      customizedMealList = list_meal.filter(
        (item) =>
          item !== dietGrid[dietGrid.length - 1]?.newTableRow.dropDownValue
      );
    }
    setDietGrid((prevState) => {
      let newTableRow = {
        id:
          dietGrid?.length > 0
            ? dietGrid[dietGrid?.length - 1]?.newTableRow.id + 1
            : 0,
        value: dayjs("2018-01-01T00:00:00.000Z"),
        addValue: customizedMealList,
        foodInTable: [],
        timePick: new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }),
        note: "",
        isRepeat: false,
        targetCalorie: "",
        dropDownValue: customizedMealList[0],
        quantitySelected: [],
      };
      let array = [];
      array.push({ newTableRow });

      return [...prevState, ...array];
    });
  };

  /* Add Food */

  const [show1, setShow1] = useState(false);
  let show2 = true;
  const [storeids, setStoreids] = useState("");
  const addFood = (id) => {
    setStoreids(id);

    setShow1(true);
  };

  /* displaySelectedFoodInTable */

  const [food, setfood] = useState([]);

  const [table, setTable] = useState([]);

  /* Nav Bar  for Plans */

  const [dietTabShow, setDietTabShow] = useState({ tabShown: "DIET PLAN" });
  function setTabShown(e) {
    $(".profileInfo li").removeClass("active");
    $(e.target).addClass("active");
    const tabShown = $(e.target).html();
    setDietTabShow((prevState) => ({ ...prevState, tabShown: tabShown }));
    props.setShowDetailPage(false);
  }

  const [dropDownValue, setDropDownValue] = useState("");

  if (
    dietGrid?.find((gridVal) => {
      return (
        !gridVal?.newTableRow?.dropDownValue ||
        gridVal?.newTableRow?.dropDownValue == ""
      );
    })
  ) {
    setDropDownValue("Early Morning");
  }

  const SelectFoodInDropdown = (e, id, data) => {
    const selectedValue = e.target.value;

    setDietGrid((prevState) => {
      if (dietGrid[id].newTableRow.id === prevState[id].newTableRow.id) {
        prevState[id].newTableRow.dropDownValue = e.target.value;
      }
      for (let i in dietGrid) {
        if (
          dietGrid[id].newTableRow.timePick === dietGrid[i].newTableRow.timePick
        ) {
          dietGrid[i].newTableRow.dropDownValue = e.target.value;
        }
      }

      return prevState;
    });

    const previousValues = dietGrid
      .filter(
        (item) =>
          parseInt(item.newTableRow?.id) !== id &&
          item.newTableRow.isRepeat == false
      )
      .map((item) => item.newTableRow.dropDownValue);

    const valueExists = previousValues.includes(selectedValue);
    setDisplayErrorForSameDropdownValue(valueExists);

    if (valueExists) {
      e.preventDefault();
      toast.error("Selected meal name already exists, please choose another", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  function handleNextMonthDate(selectedDate) {
    const date = new Date(selectedDate.$d);
    const formattedDate = date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const DateStr = formattedDate;
    const daysToAdd = 31;

    const Dates = new Date(DateStr);

    Dates.setDate(Dates.getDate() + daysToAdd);

    const nextDateStr = `${
      Dates.getMonth() + 1
    }-${Dates.getDate()}-${Dates.getFullYear()}`;

    return [nextDateStr, formattedDate];
  }
  const handleStartChange = (e) => {
    const [nextDateStr, formattedDate] = handleNextMonthDate(e);
    setStartDate(formattedDate);
  };

  // const shouldDisableDate = (date) => date < startDate;
  const handleEndChange = (e) => {
    const [nextDateStr, formattedDate] = handleNextMonthDate(e);
    if (!startDate) {
      toast.error("Please select the start date first", {
        position: toast.POSITION.TOP_CENTER,
      });
      setEndDate("");
      return;
    }

    const date1 = dayjs(startDate);
    const date2 = dayjs(formattedDate);
    const diff = date2.diff(date1, "d");

    if (diff < 0) {
      toast.error("Selected end date is earlier than start date", {
        position: toast.POSITION.TOP_CENTER,
      });
      setEndDate("");
      return;
    } else {
      setEndDate(formattedDate);
    }
  };

  const [takedata, setTakedata] = useState();
  const Takenutritions = ({ val1, val2, val3, val4, val5 }) => {
    setTakedata({ val1, val2, val3, val4, val5 });
  };
  const [dietPlan, setDietPlan] = useState("");
  const SetDietPlanName = (e) => {
    setDietPlan(e.target.value);
  };

  let activityLevel = {
    "Lightly Active": 1.375,
    "Little or No Activity": 1.2,
    "Very Active": 1.725,
    "Moderately Active": 1.55,
  };

  const [checkids, setcheckids] = useState();

  let [targetNutrition, setTargetNutrition] = useState({
    calories: props?.clientsData
      ? props?.clientsData?.bmr *
        activityLevel[props?.clientsData?.active_you_are?.title]
      : totalNutrition?.calories,
    protein: totalNutrition?.protein,
  });

  function validateAndPass() {
    let newState = JSON.parse(JSON.stringify(state));

    const filteredMeals = newState.food_meals.filter((meal) => {
      return meal.active;
    });

    if (filteredMeals.length) {
      return {
        food_meals: filteredMeals,
      };
    } else {
      throw new Error("Please Add Meals before save");
    }
  }

  const SaveNumberOfAddedFoodDetails = async () => {
    try {
      const validatedState = validateAndPass();

      if (templateDropdown) {
        if (!startDate || !endDate) {
          toast.error("Please give both Start Date and End Date", {
            position: toast.POSITION.TOP_CENTER,
          });
          handleDisableSaveBtn(false);
          return;
        }
      }
      let totalFoodDetailsAdded = {
        plan_name: dietPlan,
        leadId: params.id,
        instruction: mainPlanNotes,

        dietPlanRelationshipData: {
          start_date: startDate,
          end_date: endDate,
          waterGoal: waterTarget !== null ? waterTarget : "2",
          totalValue: {
            protein:
              targetProtein !== null && targetProtein !== 0
                ? parseFloat(targetProtein).toFixed(2)
                : undefined,
            carbs:
              targetCarbs !== null && targetCarbs !== 0
                ? parseFloat(targetCarbs).toFixed(2)
                : undefined,
            fats:
              targetFats !== null && targetFats !== 0
                ? parseFloat(targetFats).toFixed(2)
                : undefined,
            fibre:
              targetFibre !== null && targetFibre !== 0
                ? parseFloat(targetFibre).toFixed(2)
                : undefined,
            calories:
              targetCalories !== null && targetCalories !== 0
                ? parseFloat(targetCalories).toFixed(2)
                : undefined,
          },

          food_menu: validatedState.food_meals,
        },
      };

      if (!displayErrorForSameDropdownValue) {
        let planData;
        // if (!InstructionNote) {
        //   toast.error("Please add the Instruction", {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        //   return;
        // }

        let plan = totalFoodDetailsAdded?.dietPlanRelationshipData;

        for (const meal of plan?.food_menu) {
          for (const menu of meal.foodMenues) {
            for (const foodGroup of menu.food) {
              for (const foodItem of foodGroup) {
                if (!foodItem.quantity) {
                  hasEmptyQuantity = true;
                  break;
                }
              }
              if (hasEmptyQuantity) break;
            }
            if (hasEmptyQuantity) break;
          }
          if (hasEmptyQuantity) break;
        }

        console.log(plan?.start_date, "test");

        if (
          hasEmptyQuantity ||
          plan?.start_date === null ||
          plan?.end_date === null
        ) {
          toast.warning("Please fill all the empty fields", {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        } else {
          handleDisableSaveBtn(true);
        }

        axios
          .post(
            `${ATLAS_URI}/postDietGoalDetails`,
            totalFoodDetailsAdded,
            configToken
          )
          .then((res) => {
            if (res.status === 200 && InstructionNote) {
              planData = res.data;
              axios
                .post(
                  `${ATLAS_URI}/uploadPlanNote?leadId=${params.id}&planId=${res.data}`,
                  InstructionNote,
                  {
                    params: {
                      leadId: params.id,
                      planId: res.data,
                    },
                    headers: configToken["headers"],
                  }
                )
                .then((res) => {
                  if (res.status === 200) {
                    toast.success("plan created successfully", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    axiosFetch({
                      url: `/uploadPlan/diet/${planData}/${params.id}`,
                      method: "put",
                      requestConfig: totalFoodDetailsAdded,
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          axiosFetch({
                            url: `/v3/planListingCRM/${params.id}/${startDate}`,
                            method: "get",
                          }).catch((err) => console.error(err));
                        }
                      })
                      .catch((err) => console.error(err));
                    props.setAnchorEl(null);
                    props.setShowDetailPage(false);
                  }
                })
                .catch((err) => console.error(err));
            } else if (res.status === 200) {
              planData = res.data;
              toast.success("plan created successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              axios
                .post(
                  `${ATLAS_URI}/uploadPlanNote?leadId=${params.id}&planId=${res.data}&isEmpty=true`,
                  InstructionNote,
                  {
                    params: {
                      leadId: params.id,
                      planId: res.data,
                    },
                    headers: configToken["headers"],
                  }
                )
                .then((res) => {
                  if (res.status === 200) {
                    toast.success("plan created successfully", {
                      position: toast.POSITION.TOP_CENTER,
                    });
                    axiosFetch({
                      url: `/uploadPlan/diet/${planData}/${params.id}`,
                      method: "put",
                      requestConfig: totalFoodDetailsAdded,
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          axiosFetch({
                            url: `/v3/planListingCRM/${params.id}/${startDate}`,
                            method: "get",
                          }).catch((err) => console.error(err));
                        }
                      })
                      .catch((err) => console.error(err));
                    props.setAnchorEl(null);
                    props.setShowDetailPage(false);
                  }
                })
                .catch((err) => console.error(err));
              props.setAnchorEl(null);
              props.setShowDetailPage(false);
            }
          })
          .catch((err) => {
            if (err.response.data.message.includes("Food details")) {
              toast.error("Food details cannot be empty", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (
              err.response.data.message ===
              "Plan already exists with selected date"
            ) {
              toast.error("Plan already exists with selected date", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (startDate === "") {
              toast.error("Please enter the Start Date", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (endDate === "") {
              toast.error("Please enter the End Date", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (
              targetCalories === 0 ||
              targetCarbs === 0 ||
              targetFats === 0 ||
              targetFibre === 0 ||
              targetProtein === 0
            ) {
              toast.error("Please enter all the values in Target Section", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (dietPlan.trim() === "") {
              toast.error("Please enter the Diet Plan Name", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (
              err.response.data.message.includes(
                "dietPlanRelationshipData.totalValue.calories"
              )
            ) {
              toast.error("Please provide Target Calories", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (
              err.response.data.message.includes("dietPlanRelationshipData")
            ) {
              toast.error("Please provide values for all the food items", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (err.response.data.message.includes("planNote")) {
              toast.error("Please provide values for Instruction", {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error("Failed to save the plan, please try again", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            handleDisableSaveBtn(false);
          });
      } else {
        toast.error("Please choose different meal name", {
          position: toast.POSITION.TOP_CENTER,
        });
        handleDisableSaveBtn(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      handleDisableSaveBtn(false);
    }
  };

  // save as template API Work :

  const saveasTemplate = () => {
    const validatedState = validateAndPass();
    let totalFoodDetailsAdded = {
      template_name: dietPlan,
      createdBy: operator.id,
      leadId: params.id,
      // planNote: mainPlanNotes,

      dietPlanRelationshipData: {
        start_date: startDate,
        end_date: endDate,
        waterGoal: waterTarget !== null ? waterTarget : "2",
        totalValue: {
          protein:
            targetProtein !== null && targetProtein !== 0
              ? parseFloat(targetProtein).toFixed(2)
              : undefined,
          carbs:
            targetCarbs !== null && targetCarbs !== 0
              ? parseFloat(targetCarbs).toFixed(2)
              : undefined,
          fats:
            targetFats !== null && targetFats !== 0
              ? parseFloat(targetFats).toFixed(2)
              : undefined,
          fibre:
            targetFibre !== null && targetFibre !== 0
              ? parseFloat(targetFibre).toFixed(2)
              : undefined,
          calories:
            targetCalories !== null && targetCalories !== 0
              ? parseFloat(targetCalories).toFixed(2)
              : undefined,
        },
        food_menu: validatedState.food_meals,
      },
    };

    let plan = totalFoodDetailsAdded?.dietPlanRelationshipData;

    for (const meal of plan?.food_menu) {
      for (const menu of meal.foodMenues) {
        for (const foodGroup of menu.food) {
          for (const foodItem of foodGroup) {
            if (!foodItem.quantity) {
              hasEmptyQuantity = true;
              break;
            }
          }
          if (hasEmptyQuantity) break;
        }
        if (hasEmptyQuantity) break;
      }
      if (hasEmptyQuantity) break;
    }

    if (
      hasEmptyQuantity ||
      plan?.start_date === null ||
      plan?.end_date === null
    ) {
      toast.warning("Please fill all the empty fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    axios
      .post(
        `${ATLAS_URI}/postDietPlanTemplate`,
        totalFoodDetailsAdded,
        configToken
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Template Saved", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.setShowDetailPage(false);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  let location = useLocation();
  useEffect(() => {
    let LeadID;
    if (
      !window.location.href.match("/Clients/templateDetail/") &&
      !window.location.href.match("/Templates/dietTemplate")
    ) {
      if (props?.clientsData?.id === undefined) {
        LeadID = params.id;
      } else {
        LeadID = props?.clientsData?.id;
      }
      axios
        .get(`${ATLAS_URI}/getWaterGoalByLeadId/${LeadID}`, configToken)
        .catch((err) => console.error(err));
    }
    if (props.dietPlanId) {
      axios
        .get(
          `${ATLAS_URI}/getDietPlanByPlanId/${props.dietPlanId}`,
          configToken
        )
        .then((res) => {
          if (res.status == 200) {
            setEdit(true);
            let targetValues = res.data.dietPlanDetails[0].totalValue;

            if (res.data.dietPlan.Lead.water_goal !== null) {
              setWaterTarget(res.data.dietPlan.Lead.water_goal);
            }

            if (targetValues !== null) {
              if (targetValues.calories !== null) {
                setTargetCalories(
                  res.data.dietPlanDetails[0].totalValue?.calories
                );
              }
              if (targetValues.protein !== null) {
                setTargetProtein(
                  res.data.dietPlanDetails[0].totalValue?.protein
                );
              }
              if (targetValues.carbs !== null) {
                setTargetCarbs(res.data.dietPlanDetails[0].totalValue?.carbs);
              }
              if (targetValues.fibre !== null) {
                setTargetFibre(res.data.dietPlanDetails[0].totalValue?.fibre);
              }
              if (targetValues.fats !== null) {
                setTargetFats(res.data.dietPlanDetails[0].totalValue?.fats);
              }
            }

            let planNote = res.data?.dietPlan?.planNote;
            async function getHTML(link) {
              const response = await fetch(link);
              const htmlString = await response.text();
              return htmlString;
            }

            if (!!planNote) {
              getHTML(planNote)
                .then((res) => {
                  setTarget_calorie(res);
                  setMainPlanNotes(res);
                  setIndexdb(res);
                })
                .catch((err) => console.log(err));
            }

            setState((prevState) => {
              let newState = JSON.parse(JSON.stringify(prevState));
              newState.food_meals = res?.data?.dietPlanDetails[0].food_menu;
              newState.food_meals.map((meal) => (meal.active = true));

              return newState;
            });
            setDietPlan(res.data.dietPlan.plan_name);

            setStartDate(dayjs(res.data.dietPlanDetails[0].start_date));
            setEndDate(dayjs(res.data.dietPlanDetails[0].end_date));
          }
        })
        .catch((err) => console.error(err));
    }
    if (params.id) {
      axios
        .get(
          `${ATLAS_URI}/getDietPlanTemplateBylatestID/${params.id}`,
          configToken
        )
        .then((res) => {
          if (res.status == 200) {
            let targetValues = res.data.dietPlanRelationshipData.totalValue;

            if (targetValues !== null) {
              if (targetValues.calories !== null) {
                setTargetCalories(
                  res.data.dietPlanRelationshipData.totalValue?.calories
                );
              }
              if (targetValues.protein !== null) {
                setTargetProtein(
                  res.data.dietPlanRelationshipData.totalValue?.protein
                );
              }
              if (targetValues.carbs !== null) {
                setTargetCarbs(
                  res.data.dietPlanRelationshipData.totalValue?.carbs
                );
              }
              if (targetValues.fibre !== null) {
                setTargetFibre(
                  res.data.dietPlanRelationshipData.totalValue?.fibre
                );
              }
              if (targetValues.fats !== null) {
                setTargetFats(
                  res.data.dietPlanRelationshipData.totalValue?.fats
                );
              }
            }

            setState((prevState) => {
              let newState = JSON.parse(JSON.stringify(prevState));
              newState.food_meals =
                res?.data?.dietPlanRelationshipData.food_menu;
              newState.food_meals.map((meal) => (meal.active = true));

              return newState;
            });
            setDietPlan(res.data.template_name);
            setStartDate(() => {
              return moment(res?.data?.dietPlanRelationshipData.start_date);
            });
            setEndDate(() => {
              return moment(res?.data?.dietPlanRelationshipData.end_date);
            });
          }
        })
        .catch((err) => console.error(err));
    }
  }, []);

  /* delete Grid operations */

  const submit = (id, data) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Are you sure you want to delete - ${dietGrid[id].newTableRow.dropDownValue} `,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGrid(id, data),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteGrid = (id, data) => {
    setDietGrid((prevState) => {
      if (dietGrid[id].newTableRow.id == prevState[id].newTableRow.id) {
        for (let i in dietGrid) {
          dietGrid?.map((c) => {
            if (
              !dietGrid[i].newTableRow.addValue.includes(
                dietGrid[id].newTableRow.dropDownValue
              )
            ) {
              if (dietGrid[id].newTableRow.isRepeat == false) {
                return (dietGrid[i].newTableRow.addValue = [
                  ...dietGrid[i].newTableRow.addValue,
                  dietGrid[id].newTableRow.dropDownValue,
                ]);
              }
            }
            return c;
          });
        }
        const indicesToRemove = [];
        for (let i = 0; i < dietGrid?.length; i++) {
          if (
            dietGrid[id].newTableRow.dropDownValue ==
            prevState[i].newTableRow.dropDownValue
          ) {
            if (prevState[i].newTableRow.isRepeat == true) {
              indicesToRemove.push(i);
            }
          }
        }
        for (let i in dietGrid) {
          if (
            dietGrid[id].newTableRow.dropDownValue ==
            prevState[i].newTableRow.dropDownValue
          ) {
            if (
              prevState[i].newTableRow.isRepeat == true &&
              data.newTableRow.isRepeat == false
            ) {
              return prevState.filter(
                (c, id1) => id1 !== id && !indicesToRemove.includes(id1)
              );
            }
          }
        }

        return prevState.filter((c, i) => i !== id);
      }

      return prevState;
    });
  };

  /* on Change of time */

  const [timeId, settimeId] = useState("");
  const changeTime = (e, id) => {
    let newValue = e.target.value;
    settimeId(newValue);
    setDietGrid((prevState) => {
      if (dietGrid[id]?.newTableRow.id == prevState[id].newTableRow.id) {
        prevState[id].newTableRow.timePick = e.target.value;
      }
      for (let i in dietGrid) {
        if (
          dietGrid[id]?.newTableRow.dropDownValue ===
          dietGrid[i].newTableRow.dropDownValue
        ) {
          dietGrid[i].newTableRow.timePick = e.target.value;
        }
      }

      return prevState;
    });
  };

  /* Total Nutrition Consumed */

  let dietTotals = dietGrid
    ?.map((dietPlanInfo) => {
      if (
        dietPlanInfo?.newTableRow?.total &&
        dietPlanInfo?.newTableRow?.total.length > 0
      )
        return dietPlanInfo.newTableRow.total[0];
    })
    .filter((r) => r);

  if (dietTotals) {
    var totalNutrition = dietTotals.reduce(
      (acc, foodDetail) => {
        acc.carbs += parseFloat(foodDetail.carbs);
        acc.fats += parseFloat(foodDetail.fats);
        acc.protein += parseFloat(foodDetail.protein);
        acc.calories += parseFloat(foodDetail.calories);
        acc.fibre += parseFloat(foodDetail.fibre);
        return acc;
      },

      { carbs: 0, fats: 0, protein: 0, calories: 0, fibre: 0 }
    );
  }

  const [editFoodItems, setEditFoodItems] = useState(false);
  const editItems = (val) => {
    setEditFoodItems(val);
  };

  /* add Note */

  const [noteId, setnoteId] = useState(null);
  const [id3, setid3] = useState(null);
  const [show3, setshow3] = useState(false);
  const [diplayNotes, setDiplayNotes] = useState(false);
  const addNote = (e, id, id1) => {
    setDiplayNotes(true);
    setnoteId(id1);
    setshow3(true);
    setid3(id);
  };

  const closePopup = () => {
    setshow3(false);
  };

  const [item, setitem] = useState("");
  const NotesChange = (e) => {
    let notes = e.target.value;
    setitem(notes);
  };

  /* save Your Note */

  const saveYourNote = () => {
    setDietGrid((prevState) => {
      if (
        dietGrid[noteId].newTableRow.foodInTable ==
        prevState[noteId].newTableRow.foodInTable
      ) {
        prevState[noteId].newTableRow.foodInTable = prevState[
          noteId
        ].newTableRow.foodInTable?.map((note, i) => {
          if (i == id3) {
            note.note = item;
          }
          return note;
        });
      }
      return prevState;
    });
    setshow3(false);
  };

  /* Template Name Change */

  const [templateName, setTemplateName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [templateNameErr, setTemplateNameErr] = useState(false);

  const TemplateNameChange = (e) => {
    if (e.target.value === "") {
      setTemplateNameErr(true);
    } else {
      setTemplateName(e.target.value);
      setTemplateNameErr(false);
    }
  };

  /* save Template */

  const saveTemplate = () => {
    const save = {
      template_name: templateName,
      createdBy: operator.id,
      food_menu: [],
    };

    axios
      .post(`${ATLAS_URI}/postDietPlanTemplate`, save, configToken)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Template Saved", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.setShowDetailPage(false);
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  /* GET Template Details */

  const [getTemplateName, setGetTemplateName] = useState();
  const [getTemplateDetails, setGetTemplateDetails] = useState([]);
  useEffect(() => {
    axios
      .get(`${ATLAS_URI}/getDietPlanTemplate`, configToken)
      .then(
        (res) => (
          setGetTemplateName(res?.data?.map((c) => c.template_name)),
          setGetTemplateDetails(res.data)
        )
      )
      .catch((err) => console.error(err));
  }, []);

  /* template Name Change */

  const [template, setTemplate] = useState("");
  const templateNameChange = (e) => {
    setTemplate(e.target.value);
  };

  /* template Change */

  const deleteChildFoddItems = (obj) => {
    if (obj?.id) {
      var newChildFoodItem =
        dietGrid[0].newTableRow.foodInTable[0].childFoodItems.filter(
          (item) => item.id !== obj.id
        );
    }

    dietGrid[0].newTableRow.foodInTable[0].childFoodItems = newChildFoodItem;
  };
  const [templateDropdown, setTemplateDropdown] = useState();
  const templateChange = (e, id) => {
    let selectedValue = e.target.value;

    setTemplateDropdown(selectedValue);
    let getTemplateDetailsInfo = getTemplateDetails.find(
      (c) => c.id === parseInt(e.target.value)
    );

    if (getTemplateDetailsInfo) {
      setState((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        newState.food_meals = getTemplateDetailsInfo.food_menu.food_menu;
        newState?.food_meals?.map((meal) => (meal.active = true));

        return newState;
      });

      setWaterTarget(getTemplateDetailsInfo.food_menu.waterGoal);
    }
  };

  /* update Template */
  let defaultValue = getTemplateDetails.find(
    (c) => c.id == location?.state?.id
  );

  const updateTemplate = () => {
    const save1 = {
      template_name: defaultValue?.template_name
        ? defaultValue.template_name
        : templateName,
      createdBy: operator.id,
      food_menu: [],
    };

    for (let i in dietGrid) {
      const food = dietGrid[i]?.newTableRow?.foodInTable?.map((food) => {
        return {
          ...food,
          food_item: food.food_item_name ? food.food_item_name : food.food_item,
        };
      });
      save1.food_menu.push({
        name: dietGrid[i]?.newTableRow?.dropDownValue,
        food: food,
        note:
          dietGrid[i]?.newTableRow?.note !== ""
            ? dietGrid[i]?.newTableRow?.note
            : undefined,
        time: dietGrid[i].newTableRow.timePick,
        total: dietGrid[i]?.newTableRow?.total[0],
      });
    }
    if (save1.food_menu.length !== 0) {
      axios
        .put(
          `${ATLAS_URI}/putDietPlanTemplate/${location.state.id}`,
          save1,
          configToken
        )
        .then((res) =>
          toast.success("Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .catch((err) => console.error(err));
      // } else if (save1.food_menu.length !== 0) {
    } else {
      toast.error("Food Items cannot be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const removeChild = (data) => {
    setchildRow(null);
  };

  const [showplanNotes, setShowplanNotes] = useState(false);
  const [target_calorie, setTarget_calorie] = useState("");

  const planNotes = () => {
    setShowplanNotes(true);
  };
  const mainDietPlanNotes = (e) => {
    setTarget_calorie(e.target.value);
  };

  const closeNotes = () => {
    setShowplanNotes(false);
  };
  const setIndexdb = (htmlContent) => {
    const blob = new Blob([htmlContent], { type: "text/html" });
    const formData = new FormData();
    formData.append("index", blob, "instructions.html");
    SetInstructionNote(formData);
  };
  const saveMainDietPlanNotes = async () => {
    setIndexdb(target_calorie);
    setMainPlanNotes(target_calorie);
    setShowplanNotes(false);
  };

  const editTargetNutrition = (e) => {
    setTargetNutrition({
      calories: parseFloat(e.target.values),
      protein: parseFloat(e.target.values),
    });
  };

  // const onKeyUpdate = (e) => {
  //   if (e.key === "Enter") {
  //     setTargetNutrition(targetNutrition);

  //     setDiplayNotes(false);
  //   }
  // };

  const deleteNote = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Are you sure you want to delete `,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePlanNote(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deletePlanNote = () => {
    setTarget_calorie("");
    setMainPlanNotes("");
  };

  const handleChangeTargetWater = (e) => {
    let quantityss = e.target.value;
    const inputValuess = quantityss.replace(/^0+/, "");
    const numericValues = Math.round(parseInt(inputValuess, 10)) || 0;

    if (numericValues >= 1 && numericValues < 11) {
      setWaterTarget(e.target.value);
    } else {
      setWaterTarget(null);
    }
  };

  const handleChangeTargetCalories = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValue = parseInt(inputValues, 10) || 0;
    if (numericValue >= 1 && numericValue <= 9999) {
      setTargetCalories(e.target.value);
    } else {
      setTargetCalories(0);
    }
  };

  const handleChangeTargetProtein = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValues = parseInt(inputValues, 10) || 0;
    if (numericValues >= 1 && numericValues <= 9999) {
      setTargetProtein(e.target.value);
    } else {
      setTargetProtein(0);
    }
  };

  const handleChangeTargetFibre = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValues = parseInt(inputValues, 10) || 0;
    if (numericValues >= 1 && numericValues <= 9999) {
      setTargetFibre(e.target.value);
    } else {
      setTargetFibre(0);
    }
  };

  const handleChangeTargetFats = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValues = parseInt(inputValues, 10) || 0;
    if (numericValues >= 1 && numericValues <= 9999) {
      setTargetFats(e.target.value);
    } else {
      setTargetFats(0);
    }
  };

  const handleChangeTargetCarbs = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValues = parseInt(inputValues, 10) || 0;
    if (numericValues >= 1 && numericValues <= 9999) {
      setTargetCarbs(e.target.value);
    } else {
      setTargetCarbs(0);
    }
  };

  const [displayEditTargetCalorie, setDisplayEditTargetCalorie] =
    useState(false);
  const [targetCalorieID, setTargetCalorieID] = useState();

  const editTargetCalorie = (data, id) => {
    setTargetCalorieID(id);
    setDisplayEditTargetCalorie(true);
  };
  const targetCalorieChange = (e, data, id) => {
    setDietGrid((prevState) => {
      if (dietGrid[id].newTableRow.id === prevState[id].newTableRow.id) {
        prevState[id].newTableRow.targetCalorie = e.target.value;
      }
      return prevState;
    });
  };
  const today = dayjs();

  const handleSave = () => {
    toast.error("Save the Changes for Preview", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  let dietAllergies = [];
  clientData?.dietPreference?.dietAnyAllergies &&
    dietAllergies.push(clientData?.dietPreference?.dietAnyAllergies);
  clientData?.dietPreference?.otherAllergies &&
    dietAllergies.push(clientData?.dietPreference?.otherAllergies);

  return (
    <section
      className="profileInfo"
      style={{ fontSize: "16px", backgroundColor: "white" }}
    >
      {dietTabShow.tabShown === "DIET PLAN" ? (
        <div>
          <div className="targetcalories">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontFamily: " Poppins",
                  fontSize: "20px",
                  fontStyle: " normal",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Target Calories (Based on BMR)
              </p>
              <EditIcon
                sx={{ cursor: "pointer" }}
                title="Edit"
                onClick={() => setDiplayNotes(true)}
              ></EditIcon>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Calories : &nbsp;
                {diplayNotes ? (
                  <input
                    type="number"
                    onChange={handleChangeTargetCalories}
                    value={targetCalories === null ? 0 : targetCalories}
                    style={{ textAlign: "center", width: "50%" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "+" ||
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    min="1"
                  />
                ) : targetCalories === null ? (
                  0
                ) : (
                  targetCalories
                )}
                <span style={{ fontSize: "15px" }}> Cal</span>
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Protein : &nbsp;
                {diplayNotes ? (
                  <input
                    type="number"
                    value={targetProtein === null ? 0 : targetProtein}
                    onChange={handleChangeTargetProtein}
                    style={{ textAlign: "center", width: "50%" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "+" ||
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    min="1"
                  />
                ) : targetProtein === null ? (
                  0
                ) : (
                  targetProtein
                )}
                <span style={{ fontSize: "15px" }}> gm</span>
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Carbs : &nbsp;
                {diplayNotes ? (
                  <input
                    type="number"
                    value={targetCarbs === null ? 0 : targetCarbs}
                    onChange={handleChangeTargetCarbs}
                    style={{ textAlign: "center", width: "50%" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "+" ||
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    min="1"
                  />
                ) : targetCarbs === null ? (
                  0
                ) : (
                  targetCarbs
                )}
                <span style={{ fontSize: "15px" }}> gm</span>
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Fibre :&nbsp;
                {diplayNotes ? (
                  <input
                    type="number"
                    value={targetFibre === null ? 0 : targetFibre}
                    onChange={handleChangeTargetFibre}
                    style={{ textAlign: "center", width: "50%" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "+" ||
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    min="1"
                  />
                ) : targetFibre === null ? (
                  0
                ) : (
                  targetFibre
                )}
                <span style={{ fontSize: "15px" }}> gm</span>
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Fats : &nbsp;
                {diplayNotes ? (
                  <input
                    type="number"
                    value={targetFats === null ? 0 : targetFats}
                    onChange={handleChangeTargetFats}
                    style={{ textAlign: "center", width: "50%" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "+" ||
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    min="1"
                  />
                ) : targetFats === null ? (
                  0
                ) : (
                  targetFats
                )}
                <span style={{ fontSize: "15px" }}> gm</span>
              </p>
            </Box>
          </div>

          <div className="targetcalories">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontFamily: " Poppins",
                  fontSize: "20px",
                  fontStyle: " normal",
                  fontWeight: 500,
                }}
              >
                Personal Information
              </p>
              {!minimize ? (
                <KeyboardArrowDownIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setMinimize(!minimize)}
                ></KeyboardArrowDownIcon>
              ) : (
                <KeyboardArrowUpIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setMinimize(!minimize)}
                ></KeyboardArrowUpIcon>
              )}
            </Box>
            {minimize ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Diet Preference:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.dietPreference?.dietPreference
                        ? clientData.dietPreference?.dietPreference
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Prefered Cuisine:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.dietPreference?.dietPreferredCuisine
                        ? clientData.dietPreference.dietPreferredCuisine.join(
                            ", "
                          )
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Medical condition:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.medicalAssessment?.current_medical_condition
                        ? clientData.medicalAssessment.current_medical_condition.join(
                            ", "
                          )
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Allergies:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {dietAllergies ? dietAllergies.join(", ") : "--"}
                    </p>
                  </p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Daily Activity:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.workoutPreferences?.active_you_are?.title
                        ? clientData?.workoutPreferences?.active_you_are?.title
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Current Weight:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.basicInfo?.current_weight
                        ? `${clientData.basicInfo.current_weight} ${
                            clientData.basicInfo.weight_unit === 0
                              ? "Kg"
                              : "lbs"
                          }`
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Target Weight:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.basicInfo?.weight_goal
                        ? `${clientData.basicInfo.weight_goal} ${
                            clientData.basicInfo.weight_unit === 0
                              ? "Kg"
                              : "lbs"
                          }`
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      width: "20%",
                      fontFamily: " Poppins",
                      fontSize: "18px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Goal:&nbsp;
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "15px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      {clientData?.others?.fitness_goals
                        ? clientData?.others?.fitness_goals
                        : "--"}
                    </p>
                  </p>
                </Box>{" "}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="template">
            <input
              type="text"
              name="plan_name"
              value={dietPlan}
              onChange={SetDietPlanName}
              placeholder="Diet Plan Name*"
              style={{
                border: "1px solid #D0D0D0",
                width: "170px",
                borderRadius: "7px",
                height: "48px",
                textAlign: "center",
              }}
            />

            <select
              style={{
                width: "170px",
                borderRadius: "7px",
                height: "48px",
                borderColor: "#D0D0D0",
                textAlign: "center",
              }}
              onChange={templateChange}
            >
              <option>Select Template</option>
              {getTemplateDetails &&
                getTemplateDetails?.map((temp, id) => (
                  <option value={temp.id} key={id}>
                    {temp.template_name}
                  </option>
                ))}
            </select>

            <div id="datePicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleStartChange}
                  value={startDate}
                  name="startDate"
                  label="Start Date"
                  minDate={today}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </LocalizationProvider>
            </div>
            <div id="datePicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  //  shouldDisableDate={shouldDisableDate}
                  onChange={handleEndChange}
                  value={endDate}
                  name="endDate"
                  label="End Date"
                />
              </LocalizationProvider>
            </div>

            <Button
              style={{
                backgroundColor: "green",
                color: "white",
              }}
              type="button"
              disabled={disableSaveBtn}
              onClick={SaveNumberOfAddedFoodDetails}
            >
              Save
            </Button>

            <Button
              onClick={saveasTemplate}
              style={{ backgroundColor: "black", color: "white" }}
            >
              Save as Template
            </Button>

            <Button
              onClick={handleSave}
              style={{ backgroundColor: "black", color: "white" }}
            >
              <LocalPrintshopOutlinedIcon />
            </Button>
          </div>

          <div className="waterinfo">
            <div style={{ width: "100%" }}>
              <div>
                <div className="targetcalories">
                  <Box
                    sx={{
                      display: "flex",
                      height: "30px",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "20px",
                        fontStyle: " normal",
                        fontWeight: 500,
                        margin: "0px",
                      }}
                    >
                      Everyday
                    </p>
                  </Box>
                </div>
              </div>

              <div className="targetcalories">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <p
                    style={{
                      fontFamily: " Poppins",
                      fontSize: "20px",
                      fontStyle: " normal",
                      fontWeight: 500,
                    }}
                  >
                    Add New Meal
                  </p>

                  {!minimizemeal ? (
                    <KeyboardArrowDownIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setMinimizemeal(!minimizemeal)}
                    ></KeyboardArrowDownIcon>
                  ) : (
                    <KeyboardArrowUpIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setMinimizemeal(!minimizemeal)}
                    ></KeyboardArrowUpIcon>
                  )}
                </Box>

                {minimizemeal ? (
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gap: "10px",
                        // justifyContent: "space-between",
                        // gap: "1em",
                        // marginBottom: "1em",
                        // flexWrap: "wrap",
                      }}
                    >
                      {state?.food_meals?.map((each, index) => {
                        return (
                          <button
                            style={{
                              width: "100%",
                              height: "35px",

                              background: "none",
                              border: "2px solid #D0D0D0",
                              color: "grey",
                              textAlign: "center",
                              cursor: "pointer",
                              borderRadius: "6px",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor:
                                each.active === true ? "lightgray" : "white",
                            }}
                            onClick={() => handleMealClick(index, each)}
                            disabled={each.active === true ? true : false}
                            id="newMenu"
                          >
                            <div
                              style={{
                                width: "80%",
                                border: "none",
                                marginTop: "10px",
                                height: "100%",
                                color: "grey",
                                textAlign: "center",
                              }}
                            >
                              {each.mealName}
                            </div>
                            <div
                              style={{
                                borderRight: "none",
                                borderTop: "none",
                                borderBottom: "none",
                                borderLeft: "2px solid #d0d0d0",
                                height: "100%",
                                width: "20%",
                                background: "#F8F8F8",
                                color: "#757575",
                                textAlign: "center",
                                backgroundColor:
                                  each.active === true ? "#c9c9c9" : "#F8F8F8",
                              }}
                            >
                              <p style={{ marginTop: "5px" }}> +</p>
                            </div>
                          </button>
                        );
                      })}

                      <button
                        style={{
                          width: "100%",
                          height: "35px",
                          background: "black",
                          border: "2px solid black",
                          color: "grey",
                          textAlign: "center",
                          cursor: "pointer",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          const newMeal = {
                            mealName: "New",
                            active: false,
                            foodMenues: Initialmenu,
                            time: "00:00",
                            targetCalories: 0,
                          };

                          setState((prevState) => ({
                            ...prevState,
                            food_meals: [...prevState.food_meals, newMeal],
                          }));
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            border: "none",
                            height: "100%",
                            color: "white",
                            marginTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          Add New Meal
                        </div>
                        <div
                          style={{
                            borderRight: "none",
                            borderTop: "none",
                            borderBottom: "none",
                            borderLeft: "2px solid #d0d0d0",
                            height: "100%",
                            width: "20%",
                            background: "black",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ marginTop: "5px", color: "white" }}>+</p>
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <div
                className="targetcalories"
                style={{
                  height: "170px",
                  width: "100%",
                  paddingBottom: "1.5em",
                  paddingLeft: "1em",
                  paddingTop: "1.7em",
                  paddingRight: "2em",
                }}
              >
                <p
                  style={{
                    fontFamily: " Poppins",
                    fontSize: "18px",
                    fontStyle: " normal",
                    fontWeight: 500,
                  }}
                >
                  Water:
                </p>

                <Box sx={{ display: "flex", gap: "2em" }}>
                  <div>
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "16px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      Quantity
                    </p>
                    <input
                      type="number"
                      onChange={handleChangeTargetWater}
                      value={waterTarget === null ? "" : waterTarget}
                      name="qty"
                      id="waterQty"
                      placeholder="2"
                      min={0}
                      onKeyDown={(e) => {
                        if (
                          e.key === "." ||
                          e.key === "-" ||
                          e.key === "+" ||
                          e.key === "e"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      style={{
                        border: "1px solid #D0D0D0",
                        width: "60px",
                        borderRadius: "4px",
                        height: "40px",
                        textAlign: "center",
                      }}
                    />
                  </div>

                  <div>
                    <p
                      style={{
                        fontFamily: " Poppins",
                        fontSize: "16px",
                        fontStyle: " normal",
                        fontWeight: 500,
                      }}
                    >
                      Measurements
                    </p>
                    <input
                      type="text"
                      name="qty"
                      value="litres"
                      placeholder="litres"
                      id="waterQty"
                      style={{
                        border: "1px solid #D0D0D0",
                        width: "130px",
                        borderRadius: "4px",
                        height: "40px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>

          {state?.food_meals?.map((meals, mealIdx) =>
            meals.active ? (
              <>
                {!mealBox ? (
                  <div key={mealIdx} className="targetcalories">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "1em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "55%",
                        }}
                      >
                        {[
                          "Breakfast",
                          "Lunch",
                          "Dinner",
                          "Early Morning",
                          "Mid Morning",
                          "Evening Snack",
                          "Supper",
                        ].includes(meals.mealName) ? (
                          <p
                            style={{
                              fontFamily: " Poppins",
                              fontSize: "20px",
                              fontStyle: " normal",
                              fontWeight: 500,
                              marginBottom: "0px",
                            }}
                          >
                            {meals.mealName}
                          </p>
                        ) : (
                          <input
                            style={{
                              textAlign: "center",
                              outline: "none",
                              border: "1px solid lightgray",
                            }}
                            type="text"
                            value={meals.mealName}
                            onChange={(e) => {
                              updateMealcalandtime(e, mealIdx, "mealName");
                            }}
                            onClick={(e) => (e.target.readOnly = false)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") e.target.readOnly = true;
                            }}
                          />
                        )}

                        <p
                          style={{
                            fontFamily: " Poppins",
                            fontSize: "18px",
                            fontStyle: " normal",
                            fontWeight: 500,
                            marginBottom: "0px",
                          }}
                        >
                          Target Calories: &nbsp;
                          {editMealTarget ? (
                            <input
                              type="number"
                              onKeyDown={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min={1}
                              max={9999}
                              value={meals.targetCalories}
                              onChange={(e) =>
                                updateMealcalandtime(
                                  e,
                                  mealIdx,
                                  "targetCalories"
                                )
                              }
                              style={{ textAlign: "center", width: "20%" }}
                            />
                          ) : (
                            meals.targetCalories
                          )}
                          <span> Cal</span>
                        </p>

                        <p
                          style={{
                            fontFamily: " Poppins",
                            fontSize: "18px",
                            fontStyle: " normal",
                            fontWeight: 500,
                            marginBottom: "0px",
                          }}
                        >
                          Time: &nbsp;
                          {editMealTarget ? (
                            <input
                              type="time"
                              onChange={(e) =>
                                updateMealcalandtime(e, mealIdx, "time")
                              }
                              value={moment(meals.time, "HH:mm").format(
                                "HH:mm"
                              )}
                              style={{ textAlign: "center", width: "66%" }}
                            />
                          ) : (
                            moment(meals.time, "HH:mm").format("h:mm A")
                          )}
                        </p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "1em",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={() => handleAddMenu(mealIdx)}
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Add Menu
                        </Button>

                        <EditIcon
                          sx={{ cursor: "pointer" }}
                          title="Edit"
                          onClick={() => setEditMealTarget(true)}
                        ></EditIcon>

                        <DeleteOutlineIcon
                          onClick={() =>
                            handleMealDelete(mealIdx, meals.mealName)
                          }
                          sx={{ cursor: "pointer", color: "red" }}
                        />

                        <KeyboardArrowUpIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => setMealBox(!mealBox)}
                        ></KeyboardArrowUpIcon>
                      </div>
                    </div>
                    {meals?.foodMenues?.map((menue, menueIdx) => (
                      <ClientDietPlansMenuTable
                        mealIdx={mealIdx}
                        state={state}
                        setState={setState}
                        menue={menue}
                        menueIdx={menueIdx}
                      />
                    ))}
                  </div>
                ) : (
                  <div
                    key={mealIdx}
                    className="targetcalories"
                    style={{ height: "4em" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "1em",
                      }}
                    >
                      <p>
                        <b>{meals.mealName}</b>
                      </p>

                      <KeyboardArrowDownIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => setMealBox(!mealBox)}
                      ></KeyboardArrowDownIcon>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )
          )}

          <p
            style={{
              fontFamily: " Poppins",
              fontSize: "18px",
              fontStyle: " normal",
              fontWeight: 500,
            }}
          >
            Instructions
          </p>
          <div style={{ width: "100%" }}>
            <div
              className="targetcalories"
              style={{
                width: "100%",
                color: "grey",
                background: "none",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                role="button"
                tabIndex="0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                  flex: "1",
                }}
                onClick={() => setShowplanNotes(true)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    setShowplanNotes(true);
                  }
                }}
              >
                {mainPlanNotes !== "" ? (
                  <div dangerouslySetInnerHTML={{ __html: mainPlanNotes }} />
                ) : (
                  <span>Enter Instructions</span>
                )}
              </p>
              <button
                className="deleteButton"
                style={{ border: "none" }}
                onClick={() => deletePlanNote()}
              >
                <DeleteOutlineIcon />
              </button>
            </div>
          </div>

          <div className="mine">
            <BiArrowBack
              fontSize={30}
              title="Go back"
              onClick={() => {
                return window.location.href.match("/Clients/templateDetail/")
                  ? history.goBack()
                  : window.location.href.match("/Templates/dietTemplate")
                  ? props.setShowDetailPage(false)
                  : (props.setAnchorEl(null), props.setShowDetailPage(false));
              }}
            />
            {props.from !== undefined || location?.state?.from !== undefined ? (
              ""
            ) : (
              <BoxHeader title={`Diet Plans`} />
            )}

            {!!location?.state?.from ? (
              <BoxHeader title={`Diet Template`} />
            ) : (
              ""
            )}

            <div className="box-body bozero" style={{ height: "100%" }}>
              <div className="row">
                <div className="col-auto">
                  <>
                    <p>
                      <b>Template</b>
                    </p>
                    <select
                      style={{
                        width: "150px",
                        borderRadius: "7px",
                        height: "35px",
                        borderColor: "#D0D0D0",
                        textAlign: "center",
                      }}
                      onChange={templateChange}
                    >
                      <option>Select Template</option>
                      {getTemplateDetails &&
                        getTemplateDetails?.map((temp, id) => (
                          <option value={temp.id} key={id}>
                            {temp.template_name}
                          </option>
                        ))}
                    </select>
                  </>
                  &nbsp;
                </div>

                <div className="col-auto">
                  <p>
                    <b>Template Name </b>
                  </p>
                  {
                    <input
                      type="text"
                      placeholder="Template Name"
                      defaultValue={
                        window.location.href.match("/Clients/templateDetail/")
                          ? defaultValue?.template_name
                          : templateName
                      }
                      onChange={TemplateNameChange}
                      style={{
                        border: "1px solid #D0D0D0",

                        width: "150px",
                        borderRadius: "7px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    />
                  }
                  <p style={{ color: "red" }}>
                    {templateNameErr ? "Please Enter Template Name" : ""}
                  </p>
                  <p style={{ color: "red" }}>
                    {window.location.href.match("Clients/templateDetail") ? (
                      <></>
                    ) : (
                      getTemplateDetails?.map((c) =>
                        c.template_name === templateName
                          ? "Template Name already exists"
                          : null
                      )
                    )}
                  </p>
                </div>

                {location?.state?.from !== undefined ? (
                  ""
                ) : (
                  <div
                    className="col-auto"
                    style={{ position: "relative", top: "32px" }}
                  >
                    <Button
                      onClick={saveTemplate}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Save as Template
                    </Button>
                    &nbsp;
                  </div>
                )}
                <div
                  className="col-auto"
                  style={{ position: "relative", top: "32px" }}
                ></div>
              </div>
              <br />

              {window.location.href.match("/Clients/templateDetail/") ? (
                <></>
              ) : (
                <div className="row">
                  <div className="col-auto">
                    {window.location.href.match("/Clients/templateDetail/") ||
                    window.location.href.match("/Templates/dietTemplate") ? (
                      <></>
                    ) : (
                      <>
                        <p>
                          <b>Diet Plan Name </b>
                        </p>
                        {
                          <input
                            type="text"
                            name="plan_name"
                            value={dietPlan}
                            onChange={SetDietPlanName}
                            placeholder="Diet Plan Name"
                            style={{
                              border: "1px solid #D0D0D0",
                              width: "150px",
                              borderRadius: "7px",
                              height: "35px",
                              textAlign: "center",
                            }}
                          />
                        }
                      </>
                    )}
                  </div>
                  <div className="col-auto">
                    <p>
                      <b>Start Date</b>
                    </p>

                    <input
                      type="date"
                      name="startDate"
                      value={startDate}
                      min={new Date().toISOString().split("T")[0]}
                      onChange={handleStartChange}
                      onKeyDown={(e) => e.preventDefault()}
                      style={{
                        border: "1px solid #D0D0D0",
                        width: "150px",
                        borderRadius: "7px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div className="col-auto">
                    <p>
                      <b>End Date</b>
                    </p>{" "}
                    <input
                      type="date"
                      name="endDate"
                      min={startDate}
                      value={endDate}
                      onChange={handleEndChange}
                      onKeyDown={(e) => e.preventDefault()}
                      style={{
                        border: "1px solid #D0D0D0",
                        width: "150px",
                        borderRadius: "7px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </div>
              )}
              <br />
              <div className="row">
                <div style={{ width: "100%", textAlign: "left" }}>
                  <p>
                    <b>Target</b>
                  </p>
                  <table
                    className="table table-bordered"
                    style={{ border: "1px solid #D0D0D0" }}
                  >
                    <tbody>
                      <tr style={{ backgroundColor: "#e0e0e0" }}>
                        {!window.location.href.match(
                          "/Clients/templateDetail/"
                        ) &&
                        !window.location.href.match(
                          "/Templates/dietTemplate"
                        ) ? (
                          <th>Water Target (ltr)</th>
                        ) : (
                          ""
                        )}

                        <th>Target Calories(Based on BMR) (gm)</th>
                        <th>Protein (gm)</th>
                        <th>Fats (gm)</th>
                        <th>Carbs (gm)</th>
                        <th>Fibre (gm)</th>
                        <th>Action</th>
                      </tr>

                      <tr>
                        {!window.location.href.match(
                          "/Clients/templateDetail/"
                        ) &&
                        !window.location.href.match(
                          "/Templates/dietTemplate"
                        ) ? (
                          <td>
                            {diplayNotes ? (
                              <input
                                type="number"
                                onChange={handleChangeTargetWater}
                                value={waterTarget !== null ? waterTarget : 2}
                                style={{ textAlign: "center" }}
                                InputProps={{
                                  inputProps: { min: 2 },
                                }}
                                min={2}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", ".", "0", "1"].includes(
                                    e.key
                                  ) && e.preventDefault()
                                }
                              />
                            ) : waterTarget != null ? (
                              waterTarget
                            ) : (
                              2
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          {diplayNotes ? (
                            <input
                              type="number"
                              onChange={handleChangeTargetCalories}
                              value={targetCalories}
                              style={{ textAlign: "center" }}
                              onKeyPress={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min="1"
                            />
                          ) : (
                            targetCalories
                          )}
                        </td>
                        <td>
                          {diplayNotes ? (
                            <input
                              type="number"
                              value={targetProtein}
                              onChange={handleChangeTargetProtein}
                              style={{ textAlign: "center" }}
                              onKeyPress={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min="1"
                            />
                          ) : (
                            targetProtein
                          )}{" "}
                        </td>
                        <td>
                          {diplayNotes ? (
                            <input
                              type="number"
                              value={targetFats}
                              onChange={handleChangeTargetFats}
                              style={{ textAlign: "center" }}
                              onKeyPress={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min="1"
                            />
                          ) : (
                            targetFats
                          )}{" "}
                        </td>
                        <td>
                          {" "}
                          {diplayNotes ? (
                            <input
                              type="number"
                              value={targetCarbs}
                              onChange={handleChangeTargetCarbs}
                              style={{ textAlign: "center" }}
                              onKeyPress={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min="1"
                            />
                          ) : (
                            targetCarbs
                          )}{" "}
                        </td>
                        <td>
                          {diplayNotes ? (
                            <input
                              type="number"
                              value={targetFibre}
                              onChange={handleChangeTargetFibre}
                              style={{ textAlign: "center" }}
                              onKeyPress={(e) => {
                                if (e.key === "+" || e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              min="1"
                            />
                          ) : (
                            targetFibre
                          )}{" "}
                        </td>
                        <td>
                          <HiPencilAlt
                            title="View Your Note"
                            onClick={() => setDiplayNotes(true)}
                            style={{
                              color: "black",
                              fontSize: "25px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {showplanNotes ? (
                <>
                  <Modal
                    show={showplanNotes}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      position: "relative",
                      transform: "translateY(-1000px)",
                      height: "1000px",
                    }}
                  >
                    <Modal.Title>&nbsp; Instructions</Modal.Title>

                    <Modal.Body>
                      <Index
                        setTarget_calorie={setTarget_calorie}
                        target_calorie={target_calorie}
                        showplanNotes={showplanNotes}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        style={{ backgroundColor: "black", color: "white" }}
                        onClick={() => saveMainDietPlanNotes()}
                      >
                        Save
                      </Button>
                      <Button onClick={() => closeNotes()} variant="secondary">
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : (
                ""
              )}

              <div className="container">
                <div className="row">
                  <div
                    style={{ position: "relative", left: "780px", top: "40px" }}
                  >
                    <button
                      onClick={() => planNotes()}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        height: "40px",
                        width: "120px",
                        borderRadius: "7px",
                      }}
                    >
                      Add Notes
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => addMeal()}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        fontWeight: "30px",
                        position: "relative",
                        left: "920px",
                        width: "150px",
                        height: "40px",
                      }}
                    >
                      Add Meal Name
                    </button>
                  </div>
                  <div className={"col-md-6"}>
                    <Button
                      onClick={CreateTable}
                      style={{
                        position: "relative",
                        left: "430px",
                        backgroundColor: "black",
                        color: "white",
                        padding: "1px",
                        height: "40px",
                        width: "170px",
                      }}
                      disabled={
                        dietGrid[dietGrid?.length - 1]?.newTableRow?.addValue
                          ?.length == 1
                      }
                    >
                      Add New Food Grid
                    </Button>
                  </div>
                  {window.location.href.match("/Clients/templateDetail/") ? (
                    <div className="col-md-6 ">
                      {window.location.href.match(
                        "/Clients/templateDetail/"
                      ) && (
                        <button
                          style={{
                            backgroundColor: "green",
                            position: "relative",
                            left: "1300px",
                            color: "white",
                            bottom: "40px",
                          }}
                          type="button"
                          className="btn btn-primary"
                          onClick={updateTemplate}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-6 ">
                      {contextState.state.featureAccessible.find(
                        (feature) => feature.sub_feature_id === 23
                      ).is_edit &&
                        (window.location.href.match("Clients/templateDetail") ||
                        window.location.href.match(
                          "/Templates/dietTemplate"
                        ) ? (
                          <></>
                        ) : (
                          <>
                            <button
                              className="btn btn-primary"
                              onClick={SaveNumberOfAddedFoodDetails}
                              style={{
                                backgroundColor: "green",
                                position: "relative",
                                left: "1280px",
                                color: "white",
                                bottom: "40px",
                              }}
                              type="button"
                            >
                              {props.update ? "Update" : "Save"}
                            </button>
                          </>
                        ))}
                    </div>
                  )}
                </div>
              </div>

              {show1 && (
                <Popupmodel
                  show1={show1}
                  setShow1={setShow1}
                  show2={show2}
                  dietGrid={dietGrid}
                  setDietGrid={setDietGrid}
                  storeids={storeids}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  childRow={childRow}
                  childRowId={childRowId}
                  removeChild={removeChild}
                />
              )}
              {show && (
                <ClientPlansPopUp
                  show={show}
                  setshow={setshow}
                  dropDownValue={dropDownValue}
                  setDropDownValue={setDropDownValue}
                  dietGrid={dietGrid}
                  setDietGrid={setDietGrid}
                  checkids={checkids}
                />
              )}

              {show3 && (
                <Modal
                  show={show3}
                  onHide={closePopup}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    position: "relative",

                    transform: "translateY(-1000px)",
                    height: "1000px",
                  }}
                >
                  <Modal.Title>&nbsp; Notes</Modal.Title>
                  <Modal.Body>
                    <textarea
                      type="text"
                      onChange={NotesChange}
                      defaultValue={
                        dietGrid[noteId].newTableRow.foodInTable[id3].note
                      }
                      style={{
                        // width: "320px",
                        width: "100%",
                        height: "100%",
                        padding: "50px",
                        paddingTop: "0px",
                        paddingLeft: "0px",
                      }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={saveYourNote}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      {dietGrid[noteId]?.newTableRow.foodInTable[id3].note
                        ?.length > 0
                        ? "UPDATE NOTES"
                        : "ADD NOTES"}
                    </Button>
                    <Button variant="secondary" onClick={() => closePopup()}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
              <br />
              <div className="row">
                <div className="dietGrid " style={{ position: "relative" }}>
                  {dietGrid
                    ?.sort((a, b) => a?.newTableRow?.id - b?.newTableRow?.id)
                    .map((data, id) => {
                      return (
                        <div className="container" key={id}>
                          <div className="row ">
                            {data?.newTableRow?.isRepeat ? (
                              <></>
                            ) : (
                              <div
                                className="col-md-3"
                                style={{ position: "relative", right: "23px" }}
                              >
                                <p style={{ fontSize: "15px" }}>
                                  <b>Choose Your Meal</b>
                                </p>

                                <select
                                  style={{
                                    height: "55px",
                                    textAlign: "center",
                                    width: "200px",
                                    border: "1px solid #D0D0D0",
                                  }}
                                  onChange={(e) =>
                                    SelectFoodInDropdown(e, id, data)
                                  }
                                >
                                  {data?.newTableRow?.addValue?.map(
                                    (data, id) => {
                                      return (
                                        <option key={id} value={data}>
                                          {data}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            )}

                            {data?.newTableRow?.isRepeat ? (
                              <></>
                            ) : (
                              <div
                                className="col-md-3 targetCalorie"
                                style={{
                                  position: "relative",
                                  left: "80px",
                                  width: "200px",
                                  top: "45px",
                                  height: "55px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "15px",
                                    position: "relative",
                                    bottom: "45px",
                                    right: "190px",
                                  }}
                                >
                                  <b>Target Calorie</b>
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    position: "absolute",
                                    bottom: "2px",
                                    left: "50px",
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "1px",
                                      right: "25px",
                                    }}
                                  >
                                    {displayEditTargetCalorie &&
                                    targetCalorieID == id ? (
                                      <TextField
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        style={{
                                          width: "200px",
                                          height: "55px",
                                          fontSize: "20px",
                                          textAlign: "center",
                                          backgroundColor: "white",
                                        }}
                                        defaultValue={
                                          dietGrid[id]?.newTableRow
                                            ?.targetCalorie
                                        }
                                        onChange={(e) =>
                                          targetCalorieChange(e, data, id)
                                        }
                                      />
                                    ) : (
                                      <TextField
                                        style={{
                                          width: "200px",
                                          height: "55px",
                                          fontSize: "20px",
                                          textAlign: "center",
                                          backgroundColor: "white",
                                        }}
                                        placeholder="Target Calorie"
                                        value={
                                          dietGrid[id]?.newTableRow
                                            ?.targetCalorie?.length > 0
                                            ? dietGrid[id]?.newTableRow
                                                ?.targetCalorie
                                            : dietGrid[id]?.newTableRow?.total
                                                ?.length > 0
                                            ? dietGrid[
                                                id
                                              ]?.newTableRow?.total[0]?.calories?.toFixed(
                                                2
                                              )
                                            : ""
                                        }
                                        readOnly
                                      />
                                    )}
                                  </span>
                                  <span>
                                    <HiPencilAlt
                                      style={{
                                        marginLeft: "50px",
                                        position: "absolute",
                                        right: "40px",
                                        bottom: "20px",
                                        fontSize: "25px",
                                      }}
                                      onClick={() =>
                                        editTargetCalorie(data, id)
                                      }
                                    />
                                  </span>
                                </p>
                              </div>
                            )}

                            {data?.newTableRow?.isRepeat ? (
                              <></>
                            ) : (
                              <div
                                className="col-md-3 "
                                id="timePicker"
                                style={{
                                  position: "relative",

                                  bottom: "4px",
                                  right: "60px",
                                }}
                              >
                                <p style={{ fontSize: "15px" }}>
                                  <b>Start Time</b>
                                </p>

                                <input
                                  type="time"
                                  placeholder="Start Time"
                                  value={dietGrid[id]?.newTableRow?.timePick}
                                  onChange={(e) => {
                                    changeTime(e, id);
                                  }}
                                  style={{
                                    width: "200px",
                                    fontSize: "20px",
                                    height: "55px",
                                    border: "1px solid #D0D0D0",
                                    textAlign: "center",
                                  }}
                                />
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                position: "relative",
                                bottom: "50px",
                                left: "820px",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => CreateRepeatTable(data)}
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                  fontWeight: "30px",
                                  position: "relative",
                                  left: "270px",
                                  width: "170px",
                                }}
                              >
                                Alternate Menu
                              </button>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                right: "280px",
                                bottom: "12px",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => addFood(id)}
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                  fontWeight: "30px",
                                  position: "relative",
                                  left: "1200px",
                                  width: "150px",
                                  bottom: "75px",
                                }}
                              >
                                Add Food Item
                              </button>
                            </div>

                            <div
                              className="col-md-3"
                              style={{
                                position: "relative",
                                bottom: "10px",
                                right: "300px",
                              }}
                            >
                              <button
                                type="button"
                                className=""
                                style={{
                                  backgroundColor: "#FF0000",
                                  color: "white",
                                  borderRadius: "5px",
                                  borderColor: "#fff",
                                  height: "40px",
                                  position: "relative",
                                  left: "1580px",
                                  width: "40px",
                                  bottom: "120px",
                                }}
                                onClick={() => submit(id, data)}
                              >
                                <MdDeleteOutline fontSize={24} />
                              </button>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                bottom: "80px",
                                right: "25px",
                              }}
                            >
                              <GridTableClientPlans
                                setfood={setfood}
                                childRowId={childRowId}
                                setChildRowId={setChildRowId}
                                setStoreids={setStoreids}
                                childRow={childRow}
                                setchildRow={setchildRow}
                                addFood={addFood}
                                setDietGrid={setDietGrid}
                                data={data}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                storeids={storeids}
                                setTakedata={setTakedata}
                                Takenutritions={Takenutritions}
                                id={id}
                                dietGrid={dietGrid}
                                editItems={editItems}
                                childGridId={childGridId}
                                deleteChildFoddItems={deleteChildFoddItems}
                                addNote={addNote}
                                closePopup={closePopup}
                                setDiplayNotes={setDiplayNotes}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {mainPlanNotes?.length > 0 && !showplanNotes && mainPlanNotes ? (
                <>
                  <table
                    className="table table-bordered"
                    style={{
                      border: "1px solid #D0D0D0",
                      position: "relative",
                      top: "16px",
                    }}
                  >
                    <thead>
                      <th
                        style={{ height: "50px", backgroundColor: "#e0e0e0" }}
                      >
                        Instructions
                        <HiPencilAlt
                          onClick={() => planNotes()}
                          style={{
                            float: "right",
                            color: "black",
                            fontSize: "40px",
                            border: "none",
                          }}
                        />
                        <MdDeleteOutline
                          onClick={deleteNote}
                          style={{
                            float: "right",
                            color: "red",
                            fontSize: "40px",
                            border: "none",
                          }}
                        />
                      </th>
                    </thead>
                  </table>
                  <Index
                    setTarget_calorie={setTarget_calorie}
                    target_calorie={mainPlanNotes}
                    showplanNotes={showplanNotes}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ClientWorkoutPlanListing clientDetail={props.clientsData} />
      )}
    </section>
  );
}

export default ClientNotes;
