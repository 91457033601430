import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';

export default function ControlledTimepicker({
  label,
  control,
  name,
  setDate,
  date,
  error,
  helperText,
  isDisableColumn = false
}) {
  const DATE_FORMAT = 'dd-MMM-yy';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack >
            <TimePicker
              disabled={isDisableColumn}
              
              label={label}
              renderInput={params => <TextField  size="small" {...params} error={error} helperText={helperText}/>}
            //  value={new Date(date)}
              {...rest}
            />
          </Stack>
        </LocalizationProvider>
      )}
    />
  );
}
