import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import "../LiveSession.css";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import Button from "../../../components/CustomButton/Button";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

const Backdrop = (props) => {
  return <div className={"session-backdrop"} onClick={props.closeModel}></div>;
};

const OccurrenceModal = ({ closeModel, selectedRow, onSession }) => {
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState("");
  const [durationUnit, setDurationUnit] = useState("");

  const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const timeFormat = (value) => {
    const date = new Date(value);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const convertUTCFormat = (startDate, startTime) => {
    const event = new Date(`${startDate} ${startTime}`).toISOString();
    return event;
  };

  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    return [formattedDate, inputDate];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url;
    let request = {
      //   start_date: dateFormat(startDate),
      start_time: convertUTCFormat(
        dateFormat(startDate),
        timeFormat(startTime)
      ),
      duration: +duration,
      duration_unit: durationUnit,
    };

    if (selectedRow) {
      url = `/updateOccurrence/?occurrenceId=${selectedRow.occurrence_id}&meetingId=${selectedRow.zoom_meeting_id}`;
    }

    const submitData = await axiosFetch({
      url: url,
      method: "post",
      requestConfig: request,
    });

    if (submitData.status === 200) {
      toast.success(submitData.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      closeModel();
      onSession("upcoming");
    } else {
      toast.error(submitData.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (selectedRow) {
      //

      setStartDate(dayjs(convertUtcToLocalTime(selectedRow.start_time)[0]));
      setStartTime(
        dayjs(
          `${convertUtcToLocalTime(selectedRow.start_time)[0]} ${
            convertUtcToLocalTime(selectedRow.start_time)[1][1]
          }`
        )
      );

      if (selectedRow.duration) {
        setDuration(selectedRow.duration);
        setDurationUnit(selectedRow.duration_unit);
      } else {
        setDuration(selectedRow.meeting.duration);
        setDurationUnit(selectedRow.meeting.duration_unit);
      }
    }
  }, [selectedRow]);

  return (
    <form
      className="session-occruence"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="session-header sub-margin">
        <h4> Edit Current Occurrence</h4>
      </div>

      <div className="session-row format-input sub-margin">
        <div className="session-col">
          <label>Start Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disablePast
                onChange={(e) => setStartDate(e.$d)}
                value={startDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="session-col">
          <label>Start Time</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                // disablePast={isToday}
                onChange={(e) => setStartTime(e.$d)}
                value={startTime}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>

      <div className="session-row format-input sub-margin-high">
        <div className="session-col">
          <label>Duration</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={(e) => setDuration(e.target.value)}
            value={duration}
          />
        </div>
        <div className="session-col">
          <label>Duration Unit</label>
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={(e) => setDurationUnit(e.target.value)}
              value={durationUnit}
            >
              <MenuItem value={"minute"}>Minutes</MenuItem>
              <MenuItem value={"hour"}>Hours</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="session-btn-container">
        <Button
          outline
          type={"button"}
          className={"session-btn-confirm"}
          onClick={closeModel}
        >
          Cancel
        </Button>
        <Button inline type={"submit"} className={"session-btn-confirm"}>
          Update
        </Button>
      </div>
    </form>
  );
};

const EditOccurrence = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <OccurrenceModal
          closeModel={props.closeModel}
          selectedRow={props.selectedRow}
          onSession={props.onSession}
        />,
        editTableModal
      )}
    </>
  );
};

export default EditOccurrence;
