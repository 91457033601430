import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Card,
  Stack,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import ImageIcon from "@mui/icons-material/Image";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/CustomButton/Button";

import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

const imageObj = {
  paymentImage: [],
  selectedImage: [],
};

const PaymentList = () => {
  const classes = useStyles();

  let [paymentList, setPaymentList] = useState([]);
  let params = useParams();
  let history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const [paymentImage, setPaymentImage] = useState(imageObj);
  const [currenRowId, setCurrentRowId] = useState("");

  const openModal = (currentImage) => {
    setImage(currentImage);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleFileChange = (event, oldPaymentImage) => {
    // const file = event.target.files[0];
    const files = event.target.files;
    let updatedDisplayImage = [];
    let updatedSelectedImage = [];

    if (files.length + oldPaymentImage > 3) {
      toast.error("Please select up to three images.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    for (const file of files) {
      if (file && file.size < 2097152) {
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();

          reader.onload = (e) => {
            updatedDisplayImage.push(file);
            updatedSelectedImage.push(e.target.result);

            let updateProfile;

            updateProfile = {
              paymentImage: updatedDisplayImage,
              selectedImage: updatedSelectedImage,
            };
            setPaymentImage(updateProfile);
          };

          reader.readAsDataURL(file);
        }
      } else if (file) {
        toast.error("Please select an image less than 2MB", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const saveImageHandler = async () => {
    const reqObj = { paymentImage: paymentImage.paymentImage };

    const formData = new FormData();

    for (const key in reqObj) {
      formData.append(key, reqObj[key]);
    }

    try {
      const response = await axiosFetch({
        url: `/addPaymentImage/${currenRowId}`,
        method: "put",
        requestConfig: formData,
      });

      if (response.status === 200) {
        toast.success("Payment image added succsessfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getPaymentList();
      }
    } catch {}
  };

  const getPaymentList = () => {
    axiosFetch({
      url: `/getPaymentByLeadId/${params.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setPaymentList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    return [formattedDate, inputDate];
  };

  useEffect(() => {
    getPaymentList();
  }, []);

  return (
    <div>
      <Card sx={{ width: "1500px !important" }}>
        <div style={{ margin: "22px" }}>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={6}>
              <Typography
                sx={{
                  color: "#2D2D2D",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  Size: "18px",
                  borderRadius: "20px",
                }}
              >
                Payments
              </Typography>
            </Stack>
            {/* <Button inline onClick={saveImageHandler}>
              Save
            </Button> */}
          </div>
          <br />
          <TableContainer style={{ minHeight: "500px" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell align="left">
                    <b>Lead ID</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Amount</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Payment Image</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Payment Status</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Paid For</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentList.map((payment) => (
                  <TableRow sx={{ color: "#6B6B6B" }} key={payment.id}>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {payment.LeadId !== null ? payment.LeadId : "-"}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {payment.Amount !== null ? payment.Amount : "-"}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {payment?.paymentImage?.length && (
                          <>
                            {payment.paymentImage.map((img) => (
                              <img
                                onClick={() => openModal(img)}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  display: "inline-block",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}
                                alt="payment-screentshot"
                                src={img}
                              />
                            ))}
                          </>
                        )}

                        {!payment?.paymentImage?.length && (
                          <ImageIcon
                            style={{
                              fontSize: "65px",
                              color: "#EBEBEB",
                            }}
                          />
                        )}
                        {/* {(payment?.paymentImage?.length < 3 ||
                          !payment?.paymentImage?.length) && (
                          <div
                            style={{
                              cursor: "pointer",
                              position: "relative",
                              marginRight: "40px",
                            }}
                          >
                            <input
                              type="file"
                              style={{
                                width: "50px",
                                height: "50px",
                                position: "absolute",
                                opacity: 0,
                              }}
                              multiple
                              onChange={(e) =>
                                handleFileChange(
                                  e,
                                  payment?.paymentImage?.length
                                )
                              }
                            />
                            {paymentImage.selectedImage.length ? (
                              <>
                                {paymentImage.selectedImage.map((img) => (
                                  <img
                                    alt="paymentphoto"
                                    src={img}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginLeft: "8px",
                                    }}
                                  />
                                ))}
                              </>
                            ) : (
                              <ImageIcon
                                style={{
                                  fontSize: "65px",
                                  color: "#EBEBEB",
                                }}
                              />
                            )}
                          </div>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      <span className="mr-5">
                        <CheckBoxIcon
                          style={{
                            fontSize: "20px",
                            color: payment?.PaymentStatus?.color,
                          }}
                        />
                      </span>
                      <span>
                        {payment?.PaymentStatus !== null
                          ? payment.PaymentStatus.status
                          : "-"}
                      </span>
                      +
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {payment.Type !== null ? payment.Type : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#6B6B6B" }}
                    >
                      {payment.Description !== null ? payment.Description : "-"}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {payment?.product?.product_details !== null
                        ? payment?.product?.product_details?.name
                        : "-"}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {payment.Date !== null
                        ? convertUtcToLocalTime(payment.Date)[0]
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal isOpen={isOpen} onClose={closeModal}>
            <img src={image} alt="payment-screentshot" />
          </Modal>

          {/* <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </div>
      </Card>
    </div>
  );
};

export default PaymentList;
