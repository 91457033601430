import { Grid, Stack, Typography, Chip } from "@mui/material";
import React from "react";

const DataViewer = ({
  data,
  inline,
  outline,
  className,
  type,
  onClick,
  disabled = false,
}) => {
  return (
    <>
      {Object.keys(data).map((viewitem) => (
        <Grid item xs={3}>
          <Stack item>
            <Typography variant="body2" className="mb-2">
              {viewitem}
            </Typography>
            {viewitem === "Emotional Health" || viewitem === "Social Habits" ? (
              <Stack direction="row" spacing={1}>
                {data[viewitem]?.map((itm) => (
                  <Chip label={itm} />
                ))}
              </Stack>
            ) : (
              <Typography variant="h6">
                {data[viewitem] ? data[viewitem] : "--"}
              </Typography>
            )}
          </Stack>
        </Grid>
      ))}
    </>
  );
};

export default DataViewer;

//btn-disabled

//  {viewitem === "Current Medical Condition"
// ? data[viewitem].join()
// : data[viewitem]}
