import { Box, Typography, Paper, InputBase, Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import SalesTable from "./SalesTable";
import { useEffect, useState, useRef } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

export const SalesAutomation = () => {
  const history = useHistory();
  const handleAddTask = () => {
    history.push("/Settings/automation/addRule");
  };

  const [salesList, setSalesList] = useState([]);

  const isMountedRef = useRef(true);
  const getSalesData = async () => {
    try {
      const request = await axiosFetch({
        url: `/v2/getAutoAllocationRule`,
        method: "get",
      });
      if (isMountedRef.current && request.status === 200) {
        setSalesList(request.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (value) => {
    try {
      const request = await axiosFetch({
        url: `v2/getAutoAllocationRule?search=${value}`,
        method: "get",
      });

      setSalesList(request.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSalesData();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <Box sx={{ mt: "30px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "30px",
          mb: "40px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            display: "flex",
            gap: "5px",
          }}
          component="div"
        >
          <Typography sx={{ fontWeight: "600" }}>Rules List</Typography>
          <Typography sx={{ fontWeight: "600" }}>
            (Execute First Match Rule)
          </Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "0px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#000",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
              border: "20px slid #2a2a2a",
            }}
            onClick={handleAddTask}
          >
            Add Rule
          </Button>
        </Box>
      </Box>

      {salesList.length > 0 &&
        salesList.map((item) => (
          <SalesTable
            key={item.sequence}
            salesList={item}
            getSalesData={getSalesData}
          />
        ))}
    </Box>
  );
};
