import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { SalesAutomation } from "./SalesAutomation/SalesAutomation";
import Card from "../../components/Card/Card";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Automation = () => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Card>
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <Tabs
          sx={{ "& .MuiTabs-indicator": { backgroundColor: "#000" } }}
          value={tabValue}
          // onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={
              <Typography sx={{ textTransform: "capitalize", fontWeight: 600 }}>
                {"Sales Automation"}
              </Typography>
            }
            {...a11yProps(0)}
          />
        </Tabs>

        <CustomTabPanel value={tabValue} index={0}>
          <SalesAutomation />
        </CustomTabPanel>
      </Box>
    </Card>
  );
};
