import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import formdata from "./diet_preference";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const DietPreferenceForm = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [infoview, setInfoview] = useState({});

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
    profession_details: "",
  });

  const mealList = Array.from({ length: 10 }, (e, i) => i + 1);

  const [selectedMealList, setSelectedMealList] = useState("");

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors, isValid },
  } = methods;

  const handleClick = (event) => {
    setIsedit(!isedit);
  };

  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getDietPreferenceMaster`,
    })
      .then((response) => {
        setMasters({
          ...masters,
          dietPreference: [...response.data.body.diet_preference],
          cuisinePreferred: [...response.data.body.cuisine_preferred],
          mealtimings: [...response.data.body.mealtimings],
          cravings: [...response.data.body.craving_masters],
          water_intake_masters: [...response.data.body.water_intake_masters],
        });
      })
      .catch((err) => console.error(err));
  }, []);

  const allergiesfield = watch("dietAnyAllergies");
  const eatoutsidefield = watch("is_outside_food");
  const supplimentfield = watch("is_supplement_intake");

  useEffect(() => {
    if (allergiesfield == "Yes") {
    } else {
      setValue("otherAllergies", "");
    }
  }, [allergiesfield]);

  useEffect(() => {
    if (eatoutsidefield == "Yes") {
    } else {
      setValue("outside_food", "");
    }
  }, [eatoutsidefield]);

  useEffect(() => {
    if (supplimentfield == "Yes") {
    } else {
      setValue("nutrition_supplement_intake", "");
    }
  }, [supplimentfield]);

  useEffect(() => {
    if (displaydata) {
      let dietInfo = displaydata?.dietPreference;

      setSelectedMealList(dietInfo?.number_of_meals);

      setValue("dietPreference", dietInfo?.dietPreference);
      setValue("dietAnyAllergies", dietInfo?.otherAllergies ? "Yes" : "No");
      setValue("otherAllergies", dietInfo?.otherAllergies);
      setValue("dietPreferredCuisine", dietInfo?.dietPreferredCuisine);
      setValue("food_dislike", dietInfo?.food_dislike);
      setValue("number_of_meals", dietInfo?.number_of_meals);
      setValue("mealtimings", dietInfo?.mealtimings);
      setValue("water_intake", dietInfo?.water_intake);
      setValue(
        "nutrition_supplement_intake",
        dietInfo?.nutrition_supplement_intake_if_yes
      );
      setValue("outside_food", dietInfo?.outside_food);
      setValue(
        "is_outside_food",
        dietInfo?.is_outside_food === null
          ? "No"
          : dietInfo?.is_outside_food === "Yes"
          ? "Yes"
          : "No"
      );

      setValue(
        "is_supplement_intake",
        dietInfo?.nutrition_supplement_intake === null
          ? "No"
          : dietInfo?.nutrition_supplement_intake === "Yes"
          ? "Yes"
          : "No"
      );
      setInfoview({
        dietPreference: dietInfo?.dietPreference,
        dietAnyAllergies: dietInfo?.otherAllergies ? "Yes" : "No",
        otherAllergies: dietInfo?.otherAllergies,
        dietPreferredCuisine: dietInfo?.dietPreferredCuisine,
        food_dislike: dietInfo?.food_dislike,
        number_of_meals: dietInfo?.number_of_meals,
        cravings: dietInfo?.cravings,
        is_outside_food: dietInfo?.is_outside_food ? "Yes" : "No",
        outside_food: dietInfo?.outside_food,
        mealtimings: dietInfo?.mealtimings,
        water_intake: dietInfo?.water_intake,
        nutrition_supplement_intake_if_yes:
          dietInfo?.nutrition_supplement_intake ? "Yes" : "No", ///nutrition_supplement_intake
        nutrition_supplement_intake:
          dietInfo?.nutrition_supplement_intake_if_yes,
      });

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);
  const handleCancel = (event) => {
    setIsedit(false);
  };
  const handledietpreference = async (data) => {
    const isNotValid = () => {
      toast.error("Please fill all the Yes mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    };

    const {
      is_outside_food,
      outside_food,
      is_supplement_intake,
      nutrition_supplement_intake,
      dietAnyAllergies,
      otherAllergies,
    } = data;

    if (
      (is_outside_food === "Yes" && !outside_food) ||
      (is_supplement_intake === "Yes" && !nutrition_supplement_intake) ||
      (dietAnyAllergies === "Yes" && !otherAllergies)
    ) {
      isNotValid();
      return;
    }
    setIsedit(false);
    delete data.dietAnyAllergies;

    update({
      ...data,
      formtype: 2,
      number_of_meals: selectedMealList,
    });
  };
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handledietpreference(data);
        })}
      >
        {masters.dietPreference && (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  label={"Diet Preference"}
                  name={"dietPreference"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={displaydata?.dietPreference?.dietPreference}
                  options={
                    masters?.dietPreference ? masters?.dietPreference : []
                  }
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Diet Preference</Typography>
                  <Typography variant="h6">
                    {infoview?.dietPreference}
                  </Typography>
                </Stack>
              )}
            </Grid>

            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <ControlledRadioGroup
                  label={"Any Alergies"}
                  name={"dietAnyAllergies"}
                  control={control}
                  row={true}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[
                    {
                      value: "Yes",
                      label: "Yes",
                    },
                    {
                      value: "No",
                      label: "No",
                    },
                  ]}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Any Alergies</Typography>
                  <Typography variant="h6">
                    {infoview?.dietAnyAllergies}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Please Specify Allergies"}
                  name={"otherAllergies"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  isDisableColumn={getValues("dietAnyAllergies") == "No"}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Other Allergies</Typography>
                  <Typography variant="h6">
                    {infoview?.otherAllergies}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  label={"Prefered Cuisine"}
                  name={"dietPreferredCuisine"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={
                    displaydata?.dietPreference?.dietPreferredCuisine ?? []
                  }
                  options={
                    masters?.cuisinePreferred ? masters?.cuisinePreferred : []
                  }
                  freeSolo={true}
                  multiple={true}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Prefered Cuisine</Typography>
                  {infoview?.dietPreferredCuisine && (
                    <Stack direction="row" spacing={1}>
                      {infoview?.dietPreferredCuisine?.map((itm) => (
                        <Chip label={itm} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Food Dislikes"}
                  name={"food_dislike"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Food Dislikes</Typography>
                  <Typography variant="h6">{infoview?.food_dislike}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <Box>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Number of Meals
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setSelectedMealList(e.target.value)}
                      value={selectedMealList}
                      label="Number of Meals"
                    >
                      {mealList.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <div className="filter-date">
                            <span>{value}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Number of Meals</Typography>
                  <Typography variant="h6">
                    {infoview?.number_of_meals}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  multiple={true}
                  label={"Meal Timings"}
                  name={"mealtimings"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={displaydata?.dietPreference?.mealtimings ?? []}
                  options={masters?.mealtimings ? masters?.mealtimings : []}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Meal Timings</Typography>

                  {infoview?.mealtimings && (
                    <Stack direction="row" spacing={1}>
                      {infoview?.mealtimings?.map((itm) => (
                        <Chip label={itm} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  label={"cravings"}
                  name={"cravings"}
                  multiple={true}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={
                    displaydata?.dietPreference?.cravings === null
                      ? []
                      : displaydata?.dietPreference?.cravings
                  }
                  options={masters?.cravings ? masters?.cravings : []}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">cravings</Typography>
                  {infoview?.cravings && (
                    <Stack direction="row" spacing={1}>
                      {infoview?.cravings?.map((itm) => (
                        <Chip label={itm} />
                      ))}
                    </Stack>
                  )}
                </Stack>
              )}
            </Grid>

            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <Stack
                  style={{ width: "100%" }}
                  fullWidth
                  direction={"row"}
                  spacing={2}
                >
                  <ControlledRadioGroup
                    label={"Eat Outside Food"}
                    name={"is_outside_food"}
                    control={control}
                    row={true}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    options={[
                      {
                        value: "Yes",
                        label: "Yes",
                      },
                      {
                        value: "No",
                        label: "No",
                      },
                    ]}
                  />

                  <ControlledTextField
                    label={"Outside Food"}
                    name={"outside_food"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      getValues("is_outside_food") === "No" ||
                      getValues("is_outside_food") === null
                    }
                  />
                </Stack>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Eat Outside Food</Typography>
                  <Typography variant="h6">{infoview?.outside_food}</Typography>
                </Stack>
              )}
            </Grid>
            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <Stack
                  style={{ width: "100%" }}
                  fullWidth
                  direction={"row"}
                  spacing={2}
                >
                  <ControlledRadioGroup
                    label={"Suppliment Intake"}
                    name={"is_supplement_intake"}
                    control={control}
                    row={true}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    options={[
                      {
                        value: "Yes",
                        label: "Yes",
                      },
                      {
                        value: "No",
                        label: "No",
                      },
                    ]}
                  />

                  <ControlledTextField
                    label={"Suppliment Intake"}
                    name={"nutrition_supplement_intake"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      getValues("is_supplement_intake") == "No" ? true : false
                    }
                  />
                </Stack>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Suppliment Intake</Typography>
                  <Typography variant="h6">
                    {infoview?.nutrition_supplement_intake}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  label={"Water Intake"}
                  name={"water_intake"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={displaydata?.dietPreference?.water_intake}
                  options={
                    masters?.water_intake_masters
                      ? masters?.water_intake_masters
                      : []
                  }
                  freeSolo={true}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Water Intake</Typography>
                  <Typography variant="h6">{infoview?.water_intake}</Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                {" "}
                Save{" "}
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              {" "}
              Edit{" "}
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default DietPreferenceForm;

//btn-disabled
