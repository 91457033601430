import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import classes from "./SelectPaymentMode.module.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { BiLinkAlt } from "react-icons/bi";

import { BiCheck } from "react-icons/bi";

const Backdrop = (props) => {
  return <div className={classes["backdrop"]} onClick={props.closeModel}></div>;
};

const PaymentModal = (props) => {
  const [selectedPayment, setSelectedPayment] = useState(props.paymentType);
  const [selectAmount, setSelectAmount] = useState(0);

  const [ispayment, setIspayment] = useState(false);

  const copyUrl = useRef(null);
  const [buttonText, setButtonText] = useState("Copy link");

  const [oldPaymentLink, setOldPaymentLink] = useState(props.oldPaymentLink);

  const handleSelectChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (selectedPayment && selectAmount > 0) {
      setIspayment(true);
      props.submitForm(selectedPayment, selectAmount);
    }
  };

  // console.error(props);

  const copyLinkHandler = () => {
    if (copyUrl.current.value) {
      const textToCopy = copyUrl.current.value;

      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;

      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setButtonText("Copied");
    }
  };

  let linkBtn = (
    <button
      className={classes["btn-create-link"]}
      type="submit"
      disabled={ispayment}
    >
      Create link
    </button>
  );

  if (props.oldPaymentLink) {
    linkBtn = (
      <button
        className={classes["btn-create-link"]}
        type="submit"
        disabled={ispayment}
      >
        Update link
      </button>
    );
  }

  useEffect(() => {
    if (props.paymentLink) {
      setOldPaymentLink(props.paymentLink);
    }
  }, [props.paymentLink]);

  return (
    <div className={classes["card"]}>
      <div className={classes["payment-title"]}>
        <h3>Create a Payment</h3>
      </div>

      <form className={classes["form"]} onSubmit={submitHandler}>
        <Container>
          <Row className="mb-4">
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Program Name
              </label>
              <input
                disabled
                defaultValue={props.programName}
                placeholder="VMax Transformation pr..."
                type="text"
                id="propgramName"
              />
            </Col>
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Payment Type
              </label>
              <div>
                <select
                  id="paymentSelect"
                  value={selectedPayment}
                  onChange={handleSelectChange}
                >
                  <option defaultValue="">Select Payment</option>
                  <option Value="razorpay">Razorpay</option>
                  <option Value="stripe">Stripe</option>
                  <option Value="upi">UPI</option>
                </select>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Duration
              </label>
              <input
                placeholder="3"
                disabled
                defaultValue={props.programDuration[0].duration}
                type="text"
                id="propgramName"
              />
            </Col>
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Duration Unit
              </label>
              <input
                placeholder="Month"
                disabled
                defaultValue={props.programUnit}
                type="text"
                id="propgramName"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Payment Amount
              </label>
              <input
                placeholder="3"
                disabled
                defaultValue={+props.currentRow?.deal_size}
                type="text"
                id="propgramName"
              />
            </Col>
            <Col>
              <label htmlFor="propgramName" className="mb-2">
                Balance Amount
              </label>
              <input
                placeholder="Month"
                disabled
                value={
                  props.currentRow?.balance
                    ? +props.currentRow.balance - selectAmount
                    : +props.currentRow?.deal_size - selectAmount
                }
                type="text"
                id="propgramName"
              />
            </Col>
          </Row>

          <Row className="mb-4 ">
            <Col xs={7}>
              <label htmlFor="propgramName">Enter Amount</label>
              <input
                placeholder=""
                type="text"
                id="propgramName"
                className="w-100"
                onChange={(e) => setSelectAmount(e.target.value)}
                // defaultValue={props.paymentAmount}
              />
            </Col>
            <Col
              className="d-flex align-items-end"
              style={{ justifyContent: "center" }}
            >
              {linkBtn}
              {/* <button className={classes["btn-create-link"]} type="submit">Create link</button> */}
            </Col>
          </Row>

          <Row className="mb-4" style={{ width: "508px" }}>
            <Col xs={8}>
              <label htmlFor="propgramName">URL Link</label>
              <input
                placeholder=""
                type="text"
                id="propgramName"
                value={oldPaymentLink}
                disabled={true}
                ref={copyUrl}
                style={{ width: "272px" }}
              />
            </Col>
            <Col className="d-flex align-items-center mt-4">
              <button
                className={`${classes["copy-link"]}`}
                type="button"
                onClick={copyLinkHandler}
              >
                {buttonText === "Copy link" ? (
                  <span className="me-2">
                    <BiLinkAlt size={25} />
                  </span>
                ) : (
                  <span className="me-2">
                    <BiCheck size={25} />
                  </span>
                )}
                <span>{buttonText}</span>
              </button>
            </Col>
          </Row>

          <div className="w-100 d-flex justify-content-end mt-5">
            <button
              type="button"
              className={classes["btn-cancel"]}
              onClick={() => {
                props.closeModel();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={classes["btn-continue"]}
              onClick={() => {
                props.closeModel();
              }}
            >
              Ok
            </button>
          </div>
        </Container>
      </form>
    </div>
  );
};

const SelectPaymentMode = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <PaymentModal
          paymentLink={props.paymentLink}
          oldPaymentLink={props.oldPaymentLink}
          closeModel={props.closeModel}
          submitForm={props.submitForm}
          programName={props.programName}
          programUnit={props.programUnit}
          programDuration={props.programDuration}
          paymentType={props.paymentType}
          paymentAmount={props.paymentAmount}
          currentRow={props.currentRow}
        />,
        editTableModal
      )}
    </>
  );
};

export default SelectPaymentMode;
