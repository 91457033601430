import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Button from "../../../../components/CustomButton/Button";
import { toast } from "react-toastify";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";

import "./Extension.css";

const Backdrop = (props) => {
  return (
    <div
      className={"extension-backdrop"}
      onClick={() => props.closeModel(false)}
    ></div>
  );
};

const ExtensionModel = ({ closeModel, item, onUpdateProgram }) => {
  const [tenureDate, setTenureDate] = useState(null);

  const handleDays = (addDate) => {
    if (!addDate.target.value) {
      setTenureDate(null);
      return;
    }

    const currentDate = new Date(item?.end_date);
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + +addDate.target.value);
    const formattedTomorrowDate = tomorrowDate.toDateString();
    setTenureDate(dayjs(formattedTomorrowDate));
  };

  const dateFormat = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const handlesubmit = async () => {
    const formattedDate = new Date(dateFormat(tenureDate)).toISOString();

    const request = await axiosFetch({
      url: `extendProgram/${item.id}`,
      method: "post",
      requestConfig: { end_date: formattedDate },
    });

    if (request.status === 200) {
      toast.success(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      closeModel();
      onUpdateProgram();
    } else {
      toast.error(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="extension-card">
      <div className="extension-header sub-margin">
        <h4> Program Extension</h4>
      </div>

      <div className="extension-row sub-margin">
        <div className="extension-col format-input">
          <label>Program Name</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            disabled
            value={item?.product_details.name}
          />
        </div>
      </div>
      <div className="extension-row sub-margin">
        <div className="extension-col format-input">
          <label>Start Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker disabled value={dayjs(item?.start_date)} />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="extension-col format-input">
          <label>End Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker disabled value={dayjs(item?.end_date)} />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="extension-row extension-sub-margin">
        <div className="extension-col">
          <label>Add Days</label>
          <TextField
            id="outlined-size-small"
            fullWidth
            size="small"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={handleDays}
          />
        </div>
        <div className="extension-col format-input">
          <label>Extension Tenure End Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker disabled value={tenureDate} />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="session-btn-container">
        <Button
          outline
          type={"button"}
          className={"session-btn-confirm"}
          onClick={closeModel}
        >
          Cancel
        </Button>
        <Button
          inline
          type={"submit"}
          className={"session-btn-confirm"}
          onClick={handlesubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const Extension = (props) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={props.closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <ExtensionModel
          closeModel={props.closeModel}
          item={props.item}
          onUpdateProgram={props.onUpdateProgram}
        />,
        editTableModal
      )}
    </>
  );
};

export default Extension;
