import { useForm, useFieldArray, Controller } from "react-hook-form";
import Card from "../../../components/Card/Card";
import { Button, Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useHistory, useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SelectBox from "../../../components/TextFields/SelectBox";
import TextBox from "../../../components/TextFields/TextBox";
//import TextArea from "../../../components/TextFields/TextArea";
import DatePickerBox from "../../../components/TextFields/DatePickerBox";
import MultiSelectBox from "../../../components/TextFields/MultiSelectBox";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const selectListItem = [
  { id: "gender", value: "Gender" },
  { id: "language", value: "Langauge" },
  { id: "country", value: "Country" },
  { id: "profession", value: "Profession" },
  { id: "age", value: "Age" },
  { id: "medicalCondition", value: "Medical Condition" },
  { id: "consultation", value: "Consultation" },
];

const defaultCondition = [
  { id: "eq", value: "Equal to" },
  { id: "ne", value: "Not Equal to" },
  { id: "in", value: "In" },
  { id: "nin", value: "Not in" },
];

const genderList = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];

const consultationList = [
  { id: "FMC", value: "FMC" },
  { id: "FMCEXPRESS", value: "FMC EXPRESS" },
];

const initialState = {
  ruleName: "",
  description: "",
  sequence: "",
  startDate: "",
  endDate: "",
  ifSource: "",
  source: [],
  selectConsultant: [],
  addConditionList: [
    {
      selectList: "",
      selectCondition: "",
      selectType: "",
      multiSelectType: [],
    },
  ],
};

const AddRule = () => {
  const history = useHistory();

  const { state } = useLocation();
  const currentRow = state && state.currentRow;
  const isEdit = state && state.isEdit;
  const isView = state && state.isView;
  const [dropDownValues, setDropDownValues] = useState({});

  const form = useForm({
    defaultValues: isEdit || isView ? currentRow : initialState,
  });

  const { handleSubmit, control, watch, reset } = form;

  const currentSelectedList = watch("addConditionList");

  const {
    fields: fieldCondition,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    name: "addConditionList",
    control,
  });

  const onSubmit = async (formData) => {
    try {
      let request;

      if (!isEdit) {
        request = await axiosFetch({
          url: `/v2/postAutoAllocationRule`,
          method: "Post",
          requestConfig: formData,
        });
      } else {
        request = await axiosFetch({
          url: `v2/updateAutoAllocationRule`,
          method: "Put",
          requestConfig: formData,
        });
      }

      if (request.status === 200) {
        toast.success(request.data, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        history.push("/Settings/automation");
      } else {
        toast.error(request.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCondition = () => {
    if (fieldCondition.length < selectListItem.length) {
      appendCondition({
        selectList: "",
        selectCondition: "",
        selectType: "",
        multiSelectType: [],
      });
    }
  };

  const handleRemoveAddCondition = (index) => {
    if (isView) return;
    removeCondition(index);
  };

  const getAllDropdownValues = async () => {
    try {
      const request = await axiosFetch({
        url: `/v2/getSelectValuesAutoallocation`,
        method: "get",
      });

      setDropDownValues(request.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelectTypeOptions = (selectedList) => {
    let selectedTypeOption;
    let condition = defaultCondition;
    let isMultiSelect = false;

    switch (selectedList) {
      case "age": {
        selectedTypeOption = dropDownValues?.age_range;
        condition = dropDownValues?.ageConditionValues;
        break;
      }
      case "gender": {
        selectedTypeOption = genderList;
        break;
      }
      case "consultation": {
        selectedTypeOption = consultationList;
        break;
      }
      case "language": {
        selectedTypeOption = dropDownValues?.languages;
        isMultiSelect = true;
        break;
      }
      case "profession": {
        selectedTypeOption = dropDownValues?.profession;
        isMultiSelect = true;
        break;
      }
      case "medicalCondition": {
        selectedTypeOption = dropDownValues?.medical_condition;
        isMultiSelect = true;
        break;
      }
      case "country": {
        selectedTypeOption = dropDownValues?.country;
        isMultiSelect = true;
        break;
      }
    }

    return [selectedTypeOption, condition, isMultiSelect];
  };

  const handleBack = () => {
    history.push("/Settings/automation");
    reset();
  };

  useEffect(() => {
    getAllDropdownValues();
  }, []);

  return (
    <Card>
      <Box sx={{ height: "100%", mb: "100px" }}>
        <Typography
          sx={{
            display: "inline-flex",
            alignItems: "center",
            width: "cont",
            gap: "5px",
            fontSize: "18px",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
          Add Rule
        </Typography>
        <form
          className="dateForm"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          {Object.keys(dropDownValues).length > 0 && (
            <Grid container spacing={4} sx={{ mt: "40px" }}>
              <Grid item md={12}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Grid container spacing={4}>
                      <Grid item md={12}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            mb: "10px",
                          }}
                        >
                          Rule Name<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextBox
                          control={control}
                          name={"ruleName"}
                          isRequired
                          disabled={isView}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            mb: "10px",
                          }}
                        >
                          Sequence No<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextBox
                          control={control}
                          name={"sequence"}
                          isRequired
                          disabled={isView}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            mb: "10px",
                          }}
                        >
                          Start Date<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <DatePickerBox
                          name={"startDate"}
                          control={control}
                          isRequired
                          disabled={isView}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            mb: "10px",
                          }}
                        >
                          End Date<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <DatePickerBox
                          name={"endDate"}
                          control={control}
                          isRequired
                          disabled={isView}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        mb: "10px",
                      }}
                    >
                      Description<span style={{ color: "red" }}>*</span>
                    </Typography>
                    {/* <TextArea
                      control={control}
                      name={"description"}
                      isRequired
                      minRows={6}
                      disabled={isView}
                    /> */}
                    <TextBox
                      control={control}
                      name={"description"}
                      isRequired
                      // minRows={6}
                      disabled={isView}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4}>
                <SelectBox
                  control={control}
                  name={"ifSource"}
                  isRequired
                  menuList={defaultCondition}
                  inputLabel={"If Source"}
                  disabled={isView}
                />
              </Grid>
              <Grid item md={8}>
                <MultiSelectBox
                  control={control}
                  name={"source"}
                  isRequired
                  disabled={isView}
                  menuList={dropDownValues?.source}
                  inputLabel={"Source"}
                  arrangeModal={{
                    marginTop: "-200px",
                    width: "600px",
                    marginLeft: "200px",
                  }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    backgroundColor: "#2a2a2a",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }}
                  onClick={handleAddCondition}
                  disabled={isView}
                >
                  Add Conditions
                </Button>
              </Grid>

              {fieldCondition.map((field, index) => {
                const selectedList = currentSelectedList?.[index]?.selectList;
                const selectedCondition =
                  currentSelectedList?.[index]?.selectCondition;
                const [selectedTypeOption, condition, isMultiSelect] =
                  getSelectTypeOptions(selectedList);

                return (
                  <Fragment key={field.id}>
                    <Grid item md={12}>
                      <Grid container spacing={3}>
                        <Grid item md={3}>
                          <SelectBox
                            name={`addConditionList.${index}.selectList`}
                            control={control}
                            menuList={selectListItem}
                            disabled={isView}
                            isRequired
                            modalProps={{
                              style: {
                                marginTop: "-50px",
                                marginLeft: "-50px",
                                width: "auto",
                              },
                            }}
                            inputLabel={"If"}
                          />
                        </Grid>
                        {selectedList && (
                          <Grid item md={4}>
                            <SelectBox
                              name={`addConditionList.${index}.selectCondition`}
                              control={control}
                              menuList={condition}
                              disabled={isView}
                              isRequired
                              modalProps={{
                                style: {
                                  marginTop: "-50px",
                                  marginLeft: "-50px",
                                  width: "auto",
                                },
                              }}
                              inputLabel={"select Condition"}
                            />
                          </Grid>
                        )}

                        {selectedCondition && !isMultiSelect && (
                          <Grid item md={4}>
                            <SelectBox
                              name={`addConditionList.${index}.selectType`}
                              control={control}
                              menuList={selectedTypeOption}
                              disabled={isView}
                              isRequired
                              modalProps={{
                                style: {
                                  marginTop: "-50px",
                                  marginLeft: "-50px",
                                  width: "auto",
                                },
                              }}
                              inputLabel={"Select List"}
                            />
                          </Grid>
                        )}
                        {selectedCondition && isMultiSelect && (
                          <Grid item md={4}>
                            <MultiSelectBox
                              control={control}
                              name={`addConditionList.${index}.multiSelectType`}
                              isRequired
                              menuList={selectedTypeOption}
                              inputLabel={"Source"}
                              disabled={isView}
                            />
                          </Grid>
                        )}

                        <Grid
                          item
                          md={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "70px",
                          }}
                        >
                          <CloseIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleRemoveAddCondition(index)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}

              <Grid item md={11}>
                <MultiSelectBox
                  control={control}
                  name={"selectConsultant"}
                  isRequired
                  disabled={isView}
                  menuList={dropDownValues?.sales_persons}
                  inputLabel={"Select consultant"}
                  arrangeModal={{
                    marginTop: "-200px",
                    width: "600px",
                    marginLeft: "200px",
                  }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sx={{ display: "flex", gap: "20px", justifyContent: "center" }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    "&:hover": {
                      borderColor: "#000",
                    },
                    borderColor: "#000",
                    color: "#000",
                    marginBottom: "20px",
                    px: "25px",
                  }}
                  onClick={handleBack}
                >
                  close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#000",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                    marginBottom: "20px",
                    px: "25px",
                  }}
                  disabled={isView}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Box>
    </Card>
  );
};

export default AddRule;
