import {
  DialogContent,
  DialogContentText,
  Stack,
  DialogTitle,
  Dialog,
  Typography,
  Select,
  FormControl,
  Menu,
  MenuItem,
  FormHelperText,
  List,
  ListItem,
  OutlinedInput,
  Container,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

const ProgramPopup = (props) => {
  const theme = useTheme();
  let history = useHistory();
  let params = useParams();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  let [programList, setProgramList] = useState([]);
  let [selectedProgram, setSelectedProgram] = useState("");
  let [program, setProgram] = useState("");
  let [duration, setDuration] = useState("");
  let [startDate, setStartDate] = useState(null);
  let [dealSize, setDealSize] = useState("");
  let [dealCloseDate, setDealCloseDate] = useState(null);

  let [durationList, setDurationList] = useState([]);

  let [isErrProgram, setIsErrProgram] = useState(false);
  let [isErrDuration, setIsErrDuration] = useState(false);
  let [isErrStartDate, setIsErrStartDate] = useState(false);
  let [isErrDealSize, setIsErrDealSize] = useState(false);
  let [isdealCloseDate, setIsDealCloseDate] = useState(false);

  const handleClose = () => {
    setOpen(false);

    if (props.close !== undefined) {
      props.close();
    }
  };

  const handleChangeProgram = (e) => {
    setProgram(e.target.value);

    let prgObj = programList?.filter((prg) => +prg.id === +e.target.value)[0];

    if (prgObj?.duration && prgObj.duration.length) {
      setDurationList(prgObj.duration);
    }

    setIsErrProgram(false);
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
    if (newValue === null) {
      setIsErrStartDate(true);
    } else {
      setIsErrStartDate(false);
    }
  };

  const handleDealCloseDate = (value) => {
    setDealCloseDate(value);
    if (value === null) {
      setIsDealCloseDate(true);
    } else {
      setIsDealCloseDate(false);
    }
  };

  const handleChangeDealSize = (e) => {
    setDealSize(e.target.value);
    if (e.target.value === "") {
      setIsErrDealSize(true);
    } else {
      setIsErrDealSize(false);
    }
  };

  const handleChangeDuration = (e) => {
    setDuration(e.target.value);
  };

  const getProgramList = () => {
    axiosFetch({
      method: "get",
      url: `/getProducts`,
    })
      .then((res) => {
        if (res.status === 200) {
          const copyProgramList = res.data.filter((item) => {
            if (item.active_flg) {
              return item;
            }
          });

          setProgramList(copyProgramList);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const formattedDate = (date) => {
    return new Date(date)
      ?.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
  };

  const handleSubmit = () => {
    let requestObj;
    if (program === "") {
      setIsErrProgram(true);
    }

    // if (startDate === null) {
    //   setIsErrStartDate(true);
    // }

    if (dealCloseDate === null) {
      setIsDealCloseDate(true);
    }

    if (dealSize === "") {
      setIsErrDealSize(true);
    }
    if (!isErrProgram && !isErrDuration && !isErrDealSize) {
      requestObj = {
        lead_id: params.id,
        prod_id: program !== "" ? program : null,
        start_date: startDate !== null ? formattedDate(startDate) : undefined,
        deal_size: dealSize !== "" ? dealSize : null,
        duration: duration,
        expected_close_date:
          dealCloseDate !== null ? formattedDate(dealCloseDate) : undefined,
      };
      axiosFetch({
        url: "/addProductToLead",
        method: "post",
        requestConfig: requestObj,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Program added Successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            handleClose();
            props.getProductList();
          } else if (res.status === 400) {
            toast.error("Bad Request", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 401) {
            history.push("/");
          } else if (res.status === 500) {
            toast.error("Internal Server Error", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  useEffect(() => {
    getProgramList();
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      sx={{ width: "100% !important", height: "100% !important", gap: "8px" }}
    >
      <span
        onClick={handleClose}
        style={{ marginTop: "10px", marginLeft: "83%", color: "#757575" }}
      >
        <CloseIcon />
      </span>

      <DialogTitle sx={{ fontWeight: "600", size: "18px", lineHeight: "27px" }}>
        Existing Programs
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* <List>
            <ListItem> */}
          <Typography
            sx={{
              color: "black",
            }}
          >
            Program<small style={{ color: "red" }}>*</small>
          </Typography>
          <FormControl>
            <Select
              sx={{
                width: "370px",
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              placeholder="Select"
              MenuProps={{
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "center",
                  horizontal: "center",
                },
              }}
              value={program}
              onChange={handleChangeProgram}
            >
              {programList.map((program) => (
                <MenuItem key={program.id} value={program.id}>
                  {program.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "red" }}>
              {isErrProgram ? "Please select something" : ""}
            </FormHelperText>
          </FormControl>
          <br />
          <br />
          <Typography
            sx={{
              color: "black",
              width: "76px",
              height: "24px",
              fontSize: "16px",
            }}
          >
            Duration<span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl>
            <Select
              sx={{
                width: "370px",
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              placeholder="Select"
              MenuProps={{
                anchorOrigin: {
                  vertical: "center",

                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "center",
                  horizontal: "center",
                },
              }}
              value={duration}
              onChange={handleChangeDuration}
            >
              {durationList.map((duration, index) => (
                <MenuItem key={index} value={duration}>
                  {duration.duration} {duration.unit}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "red" }}>
              {isErrProgram ? "Please select something" : ""}
            </FormHelperText>
          </FormControl>
          {/* <br />
          <br />
          <Typography
            sx={{
              color: "black",
              width: "76px",
              height: "24px",
              fontSize: "16px",
            }}
          >
            Duration<span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl sx={{ width: "25ch" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              sx={{
                width: "370px",
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              value={duration}
              disabled={true}
            />
          </FormControl> */}
          <br />
          <br />
          <Typography
            sx={{
              color: "black",
              fontSize: "16px",
            }}
          >
            Start Date<span style={{ color: "red" }}>*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              slotProps={{
                textField: {
                  sx: { color: "red" },
                },
              }}
              value={startDate}
              onChange={handleChangeStartDate}
            />
          </LocalizationProvider>
          <FormHelperText sx={{ color: "red" }}>
            {isErrStartDate ? "Please select something" : ""}
          </FormHelperText>
          <br />
          <br />
          <Typography
            sx={{
              color: "black",
              fontSize: "16px",
            }}
          >
            Deal Close Date<span style={{ color: "red" }}>*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              slotProps={{
                textField: {
                  sx: { color: "red" },
                },
              }}
              value={dealCloseDate}
              onChange={handleDealCloseDate}
            />
          </LocalizationProvider>
          <FormHelperText sx={{ color: "red" }}>
            {isdealCloseDate ? "Please select something" : ""}
          </FormHelperText>
          <br />
          <br />
          <Typography
            sx={{
              color: "black",
              width: "76px",
              height: "24px",
              fontSize: "15px",
            }}
          >
            Deal Size<span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl sx={{ width: "25ch" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              sx={{
                width: "370px",
                height: "46px",
                borderRadius: "6px",
                border: "2px",
              }}
              value={dealSize}
              onChange={handleChangeDealSize}
            />
            <FormHelperText
              id="outlined-weight-helper-text"
              sx={{ color: "red" }}
            >
              {isErrDealSize ? "Please enter something" : ""}
            </FormHelperText>
          </FormControl>
          <br /> <br />
          <Container>
            <Stack direction="row" spacing={5}>
              <Button
                onClick={handleClose}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "black",
                  borderColor: "black",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "white",
                    borderColor: "black",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "black",
                  },
                }}
                onClick={handleSubmit}
              >
                Select
              </Button>
            </Stack>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default ProgramPopup;
