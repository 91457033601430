import { useLocation } from "react-router-dom";
import Table from "./components/Table";
import Header from "./components/Header";

import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useEffect, useReducer, useState } from "react";

const today = new Date();

const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");

const formattedDate = `${year}-${month}-${day}`;

const initialState = {
  report: [],
  pageSize: 25,
  page: 1,
  filter: { items: [], logicOperator: "and" },
  totalCount: 0,
  currentDate: formattedDate,
  startDate: "",
  endDate: "",
  selectedColor: "",
};

const reducer = (state, action) => {
  switch (action?.type) {
    case "report":
      const modifiedList = action.payload?.list?.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      return {
        ...state,
        report: modifiedList,
        totalCount: action.payload?.count,
      };
    case "changePage": {
      return {
        ...state,
        pageSize: action.payload?.pageSize,
        page: action.payload.page + 1,
      };
    }
    case "filterDate": {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }
    case "color": {
      return {
        ...state,
        selectedColor: action.payload,
      };
    }
    case "clear": {
      return {
        ...state,
        selectedColor: "",
        startDate: "",
        endDate: "",
      };
    }
    default:
      throw new Error("Unknown action");
  }
};

const Report = () => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const location = useLocation();
  const pathName = location.pathname.split("/")[2];
  const title =
    pathName === "progressReport" ? "Progress Report" : "Activity Report";

  const [
    {
      report,
      pageSize,
      totalCount,
      page,
      currentDate,
      selectedColor,
      startDate,
      endDate,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const getActivity = async () => {
    let url;

    if (pageSize === "All") {
      url = `/getClientsActivityReport?&startDate=${startDate}&endDate=${endDate}&userId=${userId}&group=${selectedColor}`;
    } else {
      url = `/getClientsActivityReport?&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}&userId=${userId}&group=${selectedColor}`;
    }

    const request = await axiosFetch({
      url: url,
      method: "get",
    });

    dispatch({ type: "report", payload: request.data });
  };

  const getProgress = async () => {
    let url;

    if (pageSize === "All") {
      url = `/getAllprogressDatas?&startDate=${startDate}&endDate=${endDate}&userId=${userId}`;
    } else {
      url = `/getAllprogressDatas?&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}&userId=${userId}`;
    }

    const request = await axiosFetch({
      url: url,
      method: "get",
    });

    if (request.status === 200) {
      dispatch({ type: "report", payload: request.data });
    }
  };

  useEffect(() => {
    console.log("working fine");

    if (title === "Activity Report") {
      getActivity();
    } else {
      getProgress();
    }
  }, [pageSize, page, startDate, selectedColor, endDate]);

  return (
    <div className="report-card">
      <Header title={title} dispatch={dispatch} />
      <Table
        title={pathName}
        report={report}
        count={totalCount}
        onTableDispatch={dispatch}
        currentDate={currentDate}
      />
    </div>
  );
};

export default Report;
