import { Button, Grid, Typography, Paper, TextField } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uniqueId } from "uuid";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AddDuration from "./AddDuration";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { dateConverter } from "../../../hooks/helperFunction";

import "./ProgramDetailsForm.css";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#000 !important",
    opacity: "1 !important",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    //   '&::before': {
    // 	background: `green`,
    // 	left: 12,
    //   },
    //   '&::after': {
    // 	background: `red`,
    // 	right: 12,
    //   },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const initialObject = {
  active_flg: true,
  add_to_quote_flg: false,
  auto_allocate_flg: false,
  billabe_flg: false,
  approval_flg: false,
  eff_start_date: null,
  eff_end_date: null,
  category: "",
  category_id: " ",
  comments: "",
  name: "",
  sub_category: "",
  sub_category_id: " ",
  type: "",
  type_id: " ",
  program_owner: " ",
  program_owner_role: null,
  prodImage: null,
  thumbnail: null,
  imageOne: null,
  imageTwo: null,
};

const initialDuration = [{ id: uniqueId(), duration: "", unit: "", desc: "" }];

const ProgramDetailsForm = ({ dispatch }) => {
  const location = useLocation();
  const history = useHistory();

  const MUIDateConverter = dateConverter.MUIDateConverter;
  const fileInputRef = useRef(null);
  const [addDuration, setAddDuration] = useState(initialDuration);

  const [allList, setAllList] = useState({
    isView: location.state?.view,
    isEdit: location.state?.edit,
    categoryList: [],
    typeList: [],
    ownerList: [],
    subCategory: [
      {
        id: 1,
        name: "General",
      },
      {
        id: 2,
        name: "Special",
      },
    ],
  });

  const [programList, setProgramList] = useState(initialObject);

  const currentObject = location.state?.currentRow;
  // const view = location.state?.view;

  const handleDuration = () => {
    if (addDuration.length > 10) {
      toast.warning("You can't add more than 10 Duration", {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    const newNumber = [
      ...addDuration,
      { id: uniqueId(), duration: "", unit: "", desc: "" },
    ];
    setAddDuration(newNumber);
  };

  const handleClose = (id) => {
    if (addDuration.length === 1) {
      return;
    }
    const filterDuration = addDuration.filter((item) => item.id !== id);
    setAddDuration(filterDuration);
  };

  const handleInputChange = (id, name, value) => {
    const updateRow = addDuration.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    setAddDuration(updateRow);
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event, label) => {
    const file = event.target.files[0];

    if (file && file.size < 2097152) {
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          let updateProfile;

          if (label === "prodImage") {
            updateProfile = {
              ...programList,
              prodImage: file,
              imageOne: e.target.result,
            };
            setProgramList(updateProfile);
          } else {
            updateProfile = {
              ...programList,
              thumbnail: file,
              imageTwo: e.target.result,
            };
            setProgramList(updateProfile);
          }
        };

        reader.readAsDataURL(file);
      }
    } else if (file) {
      fileInputRef.current.value = "";
      toast.error("Please select an image less than 2MB", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSeletBoxData = (item, name) => {
    let findObject = allList[name].find((value) => value.id === item);
    const updateList = { ...programList };

    if (name === "categoryList") {
      updateList.category_id = findObject.id;
      updateList.category = findObject.name;
    } else if (name === "subCategory") {
      updateList.sub_category_id = findObject.id;
      updateList.sub_category = findObject.name;
      getTypeList(updateList.sub_category_id);
    } else if (name === "typeList") {
      updateList.type_id = findObject.id;
      updateList.type = findObject.name;
    } else if (name === "ownerList") {
      updateList.program_owner = findObject.id;
      updateList.program_owner_role = findObject.Roles.Role;
    }

    setProgramList(updateList);
  };

  const handleTextBoxData = (item, name) => {
    const updateList = {
      ...programList,
      [name]: item,
    };

    setProgramList(updateList);
  };

  const getAllCategory = async () => {
    const categoryList = await axiosFetch({
      url: "/getCategory",
      method: "get",
    });

    const getOwerName = await axiosFetch({
      url: "/getUsers",
      method: "get",
    });

    if (categoryList.status === 200 && getOwerName.status === 200) {
      if (currentObject?.program_owner) {
        setAllList((pre) => {
          return {
            ...pre,
            categoryList: categoryList.data.body,
            ownerList: getOwerName.data,
          };
        });
      } else {
        setAllList((pre) => {
          return {
            ...pre,
            categoryList: categoryList.data.body,
            ownerList: getOwerName.data,
          };
        });
      }
    }
  };

  const getTypeList = async (id) => {
    const typeList = await axiosFetch({
      url: `/getTypeBySubCategory/${id}`,
      method: "get",
    });

    if (typeList.status === 200)
      setAllList((pre) => {
        return { ...pre, typeList: typeList.data.body };
      });
  };

  const handleReset = () => {
    setProgramList(initialObject);
    setAddDuration(initialDuration);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !programList.name ||
      !programList.category ||
      !programList.program_owner ||
      !programList.eff_start_date ||
      !programList.eff_end_date
    ) {
      toast.warning("Please fill in all required fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const request = {
      ...programList,
      duration: addDuration,
    };

    delete request.imageOne;
    delete request.imageTwo;

    if (
      programList.category === "Program" ||
      programList.category === "Webinar" ||
      programList.category === "Consultation" ||
      programList.category === "Trail"
    ) {
      delete request.sub_category;
      delete request.sub_category_id;
      delete request.type;
      delete request.type_id;
    }

    if (allList.isEdit) {
      delete request.vendor_id;
    }

    const formData = new FormData();

    for (const key in request) {
      if (request.duration && key === "duration") {
        request.duration.forEach((item, index) => {
          for (const key in item) {
            if (key !== "id") {
              formData.append(`duration[${index}][${key}]`, item[key]);
            }
          }
        });
      } else {
        formData.append(key, request[key]);
      }
    }

    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1], "checkkkk data");
    // }

    let sendRequest;

    if (!allList.isEdit) {
      sendRequest = await axiosFetch({
        url: `/postProduct`,
        method: "post",
        requestConfig: formData,
      });
    } else {
      sendRequest = await axiosFetch({
        url: `/updateProduct/${currentObject.id}`,
        method: "put",
        requestConfig: formData,
      });
    }

    if (sendRequest.status === 200) {
      toast.success(sendRequest.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      setAddDuration(initialDuration);
      setProgramList(initialObject);
      history.push("/Programs/programList");

      // if (
      //   programList.category === "Webinar" ||
      //   programList.category === "Consultation"
      // ) {
      //   history.push("/programsNew");
      //   return;
      // }

      // dispatch({
      //   type: "programWithOtherValues",
      //   payload: {
      //     isProgramCompleted: {
      //       createProgram: true,
      //       createFeature: true,
      //     },
      //     value: 1,
      //     program: sendRequest.data.data,
      //   },
      // });
    }
  };

  useEffect(() => {
    if (currentObject) {
      let viewAndEditObject = currentObject;
      let addIdDuration = viewAndEditObject?.duration;

      if (addDuration && addIdDuration?.length) {
        for (let i = 0; i < addIdDuration.length; i++) {
          addIdDuration[i].id = i + 1; // Assigning a unique identifier (1-based index)
        }
        viewAndEditObject.duration = addIdDuration;

        setAddDuration(addIdDuration);
      }

      getTypeList(currentObject.sub_category_id);
      setProgramList(currentObject);

      dispatch({
        type: "programWithOtherValues",
        payload: {
          isProgramCompleted: {
            createProgram: true,
            createFeature: false,
          },
          value: 0,
          program: currentObject,
        },
      });
    }
  }, [currentObject]);

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} style={{ padding: "0px 10px" }}>
        <Grid item xs={12} sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              marginRight: "20px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            disabled={allList.isView}
            onClick={handleReset}
          >
            {"Reset"}
          </Button>

          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#5D9C59",
              "&:hover": {
                backgroundColor: "#5D9C59",
              },
            }}
            disabled={allList.isView}
            type="submit"
          >
            {"Save"}
          </Button>
        </Grid>

        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "400" }}>Program Name</span>
            <span
              style={{
                color: "orange",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "18px",
                verticalAlign: "middle",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            placeholder={"Enter here"}
            size="small"
            fullWidth
            variant="outlined"
            value={programList.name}
            disabled={allList.isView}
            onChange={(e) => handleTextBoxData(e.target.value, "name")}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "400" }}>Category</span>
            <span
              style={{
                color: "orange",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "18px",
                verticalAlign: "middle",
              }}
            >
              *
            </span>
          </Typography>
          <FormControl style={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={allList.isView}
              onChange={(e) =>
                handleSeletBoxData(e.target.value, "categoryList")
              }
              value={programList.category_id}
            >
              <MenuItem value=" " disabled>
                Select
              </MenuItem>
              {allList.categoryList.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <div className="filter-date">
                    <span>{value.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
            {"Sub Category"}
          </Typography>
          <FormControl style={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={
                allList.isView
                  ? allList.isView
                  : programList.category !== "Live Studio"
              }
              onChange={(e) =>
                handleSeletBoxData(e.target.value, "subCategory")
              }
              value={programList.sub_category_id}
            >
              <MenuItem value=" " disabled>
                Select
              </MenuItem>
              {allList.subCategory.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <div className="filter-date">
                    <span>{value.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
            {"Type"}
          </Typography>
          <FormControl style={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={
                allList.isView
                  ? allList.isView
                  : programList.category !== "Live Studio"
              }
              onChange={(e) => handleSeletBoxData(e.target.value, "typeList")}
              value={programList.type_id}
            >
              <MenuItem value=" " disabled>
                Select
              </MenuItem>
              {allList.typeList.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <div className="filter-date">
                    <span>{value.name}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "400" }}>Owner Name</span>
            <span
              style={{
                color: "orange",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "18px",
                verticalAlign: "middle",
              }}
            >
              *
            </span>
          </Typography>
          <FormControl style={{ width: "100%" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={allList.isView}
              onChange={(e) => handleSeletBoxData(e.target.value, "ownerList")}
              value={programList.program_owner}
            >
              <MenuItem value=" " disabled>
                Select
              </MenuItem>
              {allList.ownerList.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <div className="filter-date">
                    <span>{value.Username}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
            {"Role"}
          </Typography>
          <TextField
            placeholder={"Enter here"}
            size="small"
            fullWidth
            disabled
            variant="outlined"
            value={programList.program_owner_role}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>
            {"Program Description"}
          </Typography>
          <TextField
            placeholder={"Enter here"}
            size="small"
            fullWidth
            variant="outlined"
            multiline
            maxRows={4}
            disabled={allList.isView}
            value={programList.comments}
            onChange={(e) => handleTextBoxData(e.target.value, "comments")}
          />
        </Grid>
        <Grid item xs={2} className="programnew-date-container">
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "400" }}>Start Date</span>
            <span
              style={{
                color: "orange",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "18px",
                verticalAlign: "middle",
              }}
            >
              *
            </span>
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disabled={allList.isView}
                value={
                  programList.eff_start_date
                    ? dayjs(programList.eff_start_date)
                    : null
                }
                onChange={(e) =>
                  handleTextBoxData(MUIDateConverter(e.$d)[0], "eff_start_date")
                }
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} className="programnew-date-container">
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontWeight: "400" }}>End Date</span>
            <span
              style={{
                color: "orange",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "18px",
                verticalAlign: "middle",
              }}
            >
              *
            </span>
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                disabled={allList.isView}
                value={
                  programList.eff_end_date
                    ? dayjs(programList.eff_end_date)
                    : null
                }
                onChange={(e) =>
                  handleTextBoxData(MUIDateConverter(e.$d)[0], "eff_end_date")
                }
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} container style={{ padding: "40px 0px 16px 0px" }}>
          <Button
            variant="contained"
            disabled={allList.isView}
            sx={{
              backgroundColor: "#2a2a2a",
              marginRight: "20px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            onClick={handleDuration}
          >
            {"Add Duration"}
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ paddingLeft: "0px !important", marginBottom: "20px" }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{"Inactive"}</Typography>
            <Android12Switch
              defaultChecked
              disabled={allList.isView}
              checked={programList.active_flg}
              onChange={(e) =>
                handleTextBoxData(e.target.checked, "active_flg")
              }
            />
            <Typography>{"Active"}</Typography>
          </Stack>
        </Grid>

        <Grid container spacing={3} style={{ padding: "0px" }}>
          {addDuration.map((value) => (
            <AddDuration
              key={value.id}
              id={value.id}
              handleClose={handleClose}
              currentRow={value}
              onInputChange={handleInputChange}
              isView={allList.isView}
            />
          ))}

          <Grid item xs={12}>
            <div>
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={allList.isView}
                      sx={{ "&.Mui-checked": { color: "#000" } }}
                      checked={programList.add_to_quote_flg}
                      onChange={(e) =>
                        handleTextBoxData(e.target.checked, "add_to_quote_flg")
                      }
                    />
                  }
                  label="Quotation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={allList.isView}
                      sx={{ "&.Mui-checked": { color: "#000" } }}
                      checked={programList.approval_flg}
                      onChange={(e) =>
                        handleTextBoxData(e.target.checked, "approval_flg")
                      }
                    />
                  }
                  label="Approval"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={allList.isView}
                      checked={programList.auto_allocate_flg}
                      sx={{ "&.Mui-checked": { color: "#000" } }}
                      onChange={(e) =>
                        handleTextBoxData(e.target.checked, "auto_allocate_flg")
                      }
                    />
                  }
                  label="Auto Allocation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={allList.isView}
                      checked={programList.billabe_flg}
                      sx={{ "&.Mui-checked": { color: "#000" } }}
                      onChange={(e) =>
                        handleTextBoxData(e.target.checked, "billabe_flg")
                      }
                    />
                  }
                  label="Billable"
                />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <div
              style={{
                width: "396px",
                height: "180px",
                borderRadius: "3.333px",
                border: "1px solid #D7D7D7",
                background: "#F8F8F8",
                cursor: "pointer",
                position: "relative",
                marginRight: "40px",
              }}
            >
              <input
                type="file"
                disabled={allList.isView}
                style={{
                  width: "396px",
                  height: "180px",
                  position: "absolute",
                  opacity: 0,
                }}
                onChange={(e) => handleFileChange(e, "prodImage")}
              />

              {programList.imageOne ? (
                <img
                  alt="prodImage"
                  src={programList.imageOne}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CameraAltIcon
                    sx={{ color: "#989898", width: "42px", height: "34px" }}
                  />
                  <Typography sx={{ color: "#989898", fontSize: "14px" }}>
                    {"Add Product image"}
                  </Typography>
                  <Typography sx={{ color: "#D7D7D7", fontSize: "12px" }}>
                    {"JPG, GIF or PNG, Max size of 2MB"}
                  </Typography>
                </div>
              )}
            </div>

            <div
              style={{
                width: "396px",
                height: "180px",
                flexShrink: 0,
                borderRadius: "3.333px",
                border: "1px solid #D7D7D7",
                background: "#F8F8F8",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "14px",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={handleContainerClick}
            >
              <input
                type="file"
                disabled={allList.isView}
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, "thumbnail")}
              />
              {programList.imageTwo ? (
                <img
                  alt="prodImage"
                  src={programList.imageTwo}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CameraAltIcon
                    sx={{ color: "#989898", width: "42px", height: "34px" }}
                  />
                  <Typography sx={{ color: "#989898", fontSize: "14px" }}>
                    {"Add Product image"}
                  </Typography>
                  <Typography sx={{ color: "#D7D7D7", fontSize: "12px" }}>
                    {"JPG, GIF or PNG, Max size of 2MB"}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default ProgramDetailsForm;
