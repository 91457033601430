import Modal from "../../components/Modal/Modal";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import styles from "./ServertelForm.module.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCallerPopup } from "../store/Call/CallerPopupContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const InitalDispositionList = [
  { id: 1, name: "Connected" },
  { id: 2, name: "Not Connected" },
];

const ServertelForm = ({ isOpen, onClose }) => {
  const location = useLocation();
  const pathName = location.pathname.toLowerCase().includes("lead")
    ? "Lead"
    : "Client";
  const isLead = pathName === "Lead";
  const { selectedItems, setIsVisible, callId, pendingInbound } =
    useCallerPopup();
  const userId = JSON.parse(window.localStorage.getItem("operator"))?.id;
  const [dropDownList, setDropDownList] = useState({
    categoryList: [],
    typeList: [],
    stageList: [],
    // dispositionList: [],
    subDisposistionList: [],
    grandDispositionList: [],
  });

  const [dispositionList, setDispositionList] = useState(InitalDispositionList);

  const dateConverter = (value) => {
    const date = new Date(value);
    const isoString = date.toISOString();

    return isoString;
  };

  const form = useForm({
    defaultValues: {
      interaction_on: dateConverter(new Date()),
      interaction_category: "",
      interaction_type: "",
      lead_stage: "",
      lead_disposition: "",
      lead_sub_disposition: "",
      grand_disposition: "",
      next_followup_on: null,
      reviewNote: "",
    },
  });

  const { handleSubmit, control, reset, watch, setValue } = form;

  const currentStage = watch("lead_stage");
  const currentDisposition = watch("lead_disposition");
  const currentSubDisposition = watch("lead_sub_disposition");
  const currentCategory = watch("interaction_category");
  const allowGrandDisposistion =
    +currentStage === 3 &&
    +currentDisposition === 1 &&
    +currentSubDisposition === 4;

  const allowSubDisposition = isLead
    ? (+currentStage !== 2 || +currentDisposition !== 1) &&
      currentStage &&
      +currentStage !== 5 &&
      +currentStage !== 7
    : currentDisposition !== 1;

  const allowDisposition =
    (currentStage && +currentStage !== 5 && +currentStage !== 7) || !isLead;

  function convertEmptyToNull(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key] === "" ? null : obj[key];
      }
    }
    return newObj;
  }

  const onSubmit = async (formData) => {
    const formObject = {
      ...formData,
      leadId: selectedItems.leadId,
      userId: userId,
      servetel_call_id: callId,
    };

    if (!isLead) {
      delete formObject.grand_disposition;
    }

    if (pendingInbound.pendingInbound) {
      formObject.leadId = pendingInbound.id;
      formObject.servetel_call_id = pendingInbound.inbound_call_id;
    }

    const response = await axiosFetch({
      url: "/createInteraction",
      method: "post",
      requestConfig: convertEmptyToNull(formObject),
    });

    if (response.status === 200) {
      toast.success("Interaction Saved!", {
        position: toast.POSITION.TOP_CENTER,
      });
      reset("");
      onClose();
      setIsVisible(false);
    }
  };

  const getInteractionList = async () => {
    const response = await axiosFetch({
      url: "/getCallDropdownValues",
      method: "get",
    });

    if (response.status === 200) {
      const data = response.data?.body;

      setDropDownList(() => {
        return {
          ...dropDownList,
          categoryList: data.interactionCategories,
          typeList: data.interactionTypes,
          stageList: data.leadStages,
          // dispositionList: data.leadDispositions,
        };
      });
    }
  };

  const getSubDispositionList = async (subDis, stage, category) => {
    let url;

    if (isLead) {
      url = `/getLeadSubDispositionDropdown/${subDis}/${stage}`;
    } else {
      url = `/getClientSubDispositionDropdown?dispositionId=${subDis}&interactionCategoryId=${category}`;
    }

    const response = await axiosFetch({
      url: url,
      method: "get",
    });

    if (response.status === 200) {
      const data = response.data.body;

      setDropDownList(() => {
        return {
          ...dropDownList,
          subDisposistionList: data,
        };
      });
    }
  };

  const getGrandDispositionList = async (dis, subDis) => {
    const response = await axiosFetch({
      url: `/getLeadNotInterestSubDispositionDropdown/${dis}/${subDis}`,
      method: "get",
    });

    if (response.status === 200) {
      const data = response.data.body;
      setDropDownList(() => {
        return {
          ...dropDownList,
          grandDispositionList: data,
        };
      });
    }
  };

  useEffect(() => {
    getInteractionList();
  }, [isOpen]);

  useEffect(() => {
    if (currentStage) {
      setValue("lead_disposition", "");
      setValue("lead_sub_disposition", "");
    }
  }, [currentStage]);

  useEffect(() => {
    if (currentDisposition) {
      setValue("lead_sub_disposition", "");
      setValue("grand_disposition", "");
    }
  }, [currentDisposition]);

  useEffect(() => {
    if (currentStage === 10 || currentStage === 11) {
      const filterDisposition = dispositionList.filter((item) => item.id === 2);
      setDispositionList(filterDisposition);
    } else {
      setDispositionList(InitalDispositionList);
    }
  }, [currentStage]);

  useEffect(() => {
    if (currentDisposition) {
      getSubDispositionList(currentDisposition, currentStage, currentCategory);
    }
  }, [currentStage, currentDisposition, currentCategory]);

  useEffect(() => {
    if (allowGrandDisposistion) {
      getGrandDispositionList(currentDisposition, currentSubDisposition);
    }
  }, [currentSubDisposition]);

  useEffect(() => {
    setValue("interaction_category", selectedItems?.category);
  }, [selectedItems]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: { xs: "1000px", md: "800px", sm: "500px" },
            marginBottom: "30px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xl={12} sm={12} md={12}>
              <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                Interaction
              </Typography>
            </Grid>
            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>
                Next Follow Up
              </Typography>
              <Controller
                name="next_followup_on"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DateTimePicker
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(dateConverter(event?.$d));
                      }}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs(new Date())}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                      className={styles.custom_datetimepicker}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>
                Category<span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="interaction_category"
                rules={{ required: "required" }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      disabled={
                        isLead ? !pendingInbound?.pendingInbound : false
                      }
                      displayEmpty
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      {...field}
                      error={!!error}
                    >
                      <MenuItem value="">
                        <span style={{ color: "#9da5b1" }}>Category</span>
                      </MenuItem>

                      {dropDownList?.categoryList.map((task) => (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          sx={{ fontSize: "16px" }}
                        >
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>
                Type<span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="interaction_type"
                rules={{ required: "required" }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      displayEmpty
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      {...field}
                      error={!!error}
                    >
                      <MenuItem value="">
                        <span style={{ color: "#9da5b1" }}>Select</span>
                      </MenuItem>

                      {dropDownList?.typeList.map((task) => (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          sx={{ fontSize: "16px" }}
                        >
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {isLead && (
              <Grid item xl={4} sm={6} md={6}>
                <Typography sx={{ marginBottom: "10px" }}>
                  Stage<span style={{ color: "red" }}>*</span>
                </Typography>
                <Controller
                  name="lead_stage"
                  rules={{ required: "required" }}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small">
                      <Select
                        displayEmpty
                        sx={{
                          fontSize: "16px",
                          color: "black",
                          backgroundColor: "white",
                        }}
                        {...field}
                        error={!!error}
                      >
                        <MenuItem value="">
                          <span style={{ color: "#9da5b1" }}>Select</span>
                        </MenuItem>

                        {dropDownList?.stageList.map((task) => (
                          <MenuItem
                            value={task.id}
                            key={task.id}
                            sx={{ fontSize: "16px" }}
                          >
                            {task.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            )}

            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>
                Disposition<span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="lead_disposition"
                rules={{
                  required: allowDisposition ? "required" : false,
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      displayEmpty
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      disabled={+currentStage === 5 || +currentStage === 7}
                      {...field}
                      error={!!error}
                    >
                      <MenuItem value="">
                        <span style={{ color: "#9da5b1" }}>Select</span>
                      </MenuItem>

                      {dispositionList.map((task) => (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          sx={{ fontSize: "16px" }}
                        >
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>
                Sub Disposition<span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="lead_sub_disposition"
                rules={{
                  required: allowSubDisposition ? "required" : false,
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small">
                    <Select
                      displayEmpty
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        backgroundColor: "white",
                      }}
                      {...field}
                      error={!!error}
                      disabled={+currentStage === 5 || +currentStage === 7}
                    >
                      <MenuItem value="">
                        <span style={{ color: "#9da5b1" }}>Select</span>
                      </MenuItem>

                      {dropDownList?.subDisposistionList.map((task) => (
                        <MenuItem
                          value={task.id}
                          key={task.id}
                          sx={{ fontSize: "16px" }}
                        >
                          {task.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={true}>
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            {isLead && (
              <Grid item xl={4} sm={6} md={6}>
                <Typography sx={{ marginBottom: "10px" }}>
                  Grand Disposition<span style={{ color: "red" }}>*</span>
                </Typography>
                <Controller
                  name="grand_disposition"
                  rules={{
                    required:
                      +currentStage === 3 && +currentDisposition === 1
                        ? "required"
                        : false,
                  }}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small">
                      <Select
                        displayEmpty
                        disabled={!allowGrandDisposistion}
                        sx={{
                          fontSize: "16px",
                          color: "black",
                          backgroundColor: "white",
                        }}
                        {...field}
                        error={!!error}
                      >
                        <MenuItem value="">
                          <span style={{ color: "#9da5b1" }}>
                            Please Select Category
                          </span>
                        </MenuItem>

                        {dropDownList?.grandDispositionList.map((task) => (
                          <MenuItem
                            value={task.id}
                            key={task.id}
                            sx={{ fontSize: "16px" }}
                          >
                            {task.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
            )}

            <Grid item xl={4} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>Date & Time</Typography>
              <Controller
                name="interaction_on"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DateTimePicker
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      {...field}
                      value={field.value && dayjs(new Date())}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xl={12} sm={6} md={6}>
              <Typography sx={{ marginBottom: "10px" }}>Notes</Typography>
              <Controller
                name={"reviewNote"}
                rules={{
                  required: +currentDisposition === 1 ? "required" : false,
                  validate: (value) => {
                    if (
                      +currentDisposition === 1 &&
                      !isLead &&
                      value.length < 100
                    ) {
                      return "Must be at least 100 characters long";
                    }
                    return true;
                  },
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xl={4} sm={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  border: "20px slid #2a2a2a",
                }}
                // onClick={handleAddTask}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default ServertelForm;
